import {isProduction} from '@marketplace/shared-lib/src/utils/envUtils'

const userPortalLinkProd = 'https://s-kayttajatili.fi/sso'
const userPortalLinkTest = 'https://test.s-kayttajatili.fi/sso'
const digiLink = 'https://digili.s-cloud.fi'

const attachBonusCardLinkTest = 'https://tunnistus-test.s-ryhma.fi/v2/attach-bonuscard-init'
const attachBonusCardLinkProd = 'https://tunnistus.s-ryhma.fi/v2/attach-bonuscard-init'

const createBase64Url = (url: string) => {
  const buffer = Buffer.from(url)
  return buffer.toString('base64')
}

const getSupportedLang = (fallbackLang: string, lang?: string): string => {
  switch (lang) {
    case 'en':
      return 'en'
    case 'sv':
      return 'sv'
    case 'fi':
      return 'fi'
    default:
      return fallbackLang
  }
}

/**
 * Helper function for generating correct S-kayttajatili UI link in prod and test accounts
 * @param lang string of lang (supports fi, en and sv in s-kayttajatili)
 * @returns either fi, en, sv. if provided unsupported lang appreviation, defaults to fallback ('fi' if not set)
 */
export const getLinkToUserProfile = (lang?: string, fallback: 'fi' | 'en' | 'sv' = 'fi') => {
  const userProfileSsoLink = isProduction ? userPortalLinkProd : userPortalLinkTest
  const supportedLang = getSupportedLang(fallback, lang)
  return `${userProfileSsoLink}?ui_locales=${supportedLang}`
}

export const getLinkToJoinLoyalty = (lang?: string, fallback: 'fi' | 'sv' = 'fi') => {
  const supportedLang = getSupportedLang(fallback, lang)
  return `${digiLink}/?initialLang=${supportedLang}`
}

/**
 * Provides the link to attach S-Bonus card flow, lang locale and return url if attaching bonus card failed/succeeded
 * NOTE: THIS WILL NOT WORK IN MRs since all url domains need to be whitelisted!
 * @param lang will be used in defining language for attach loyalty card
 * @param fallback
 * @returns Url as string
 */
export const getLinkToAttachLoyaltyCard = (lang?: string, fallback: 'fi' | 'sv' | 'en' = 'fi'): string => {
  const attachBonusCardUrl = isProduction ? attachBonusCardLinkProd : attachBonusCardLinkTest
  const supportedLang = getSupportedLang(fallback, lang)
  const currentUrl = createBase64Url(window?.location.href)
  return `${attachBonusCardUrl}?return_url=${currentUrl}&ui_locales=${supportedLang}`
}
