import {Text} from '@s-group/design-system-components'
import {ModalState} from 'Components/Modal/modalTypes'
import {Success} from 'Icons'
import {useContext} from 'react'
import Feedback from 'Components/Feedback/Feedback'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {spacingCustom} from 'theme'
import ModalContext from '../../state/ModalContext'
import {PopupButton, PopupTitle, PopupWrapper, TakeawayFeedbackWrapper} from './PopupStyles'

interface PopupProps {
  message: string
  bodyText?: string
  testId?: string
  buttonText?: string | null
  feedbackText?: string
  tableType?: TableType
}

const SuccessfulPopup = ({message, bodyText, testId, buttonText, feedbackText, tableType}: PopupProps) => {
  const {setModalState} = useContext(ModalContext)
  return (
    <PopupWrapper data-testid={testId} role='alert'>
      <Success />
      <PopupTitle variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
        {message}
      </PopupTitle>
      {bodyText && (
        <Text variant='body' sizing='small'>
          {bodyText}
        </Text>
      )}
      {setModalState && buttonText && (
        <PopupButton
          color='neutral'
          variant='filled'
          rounding='small'
          data-testid={testId ? `${testId}-button` : undefined}
          onClick={() => setModalState(ModalState.Closed)}
        >
          {buttonText}
        </PopupButton>
      )}
      {tableType === TableType.PICKUP && (
        <TakeawayFeedbackWrapper>
          <Feedback
            feedbackDescription={feedbackText}
            tableType={tableType}
            compensateDividerPadding={spacingCustom.medium}
          />
        </TakeawayFeedbackWrapper>
      )}
    </PopupWrapper>
  )
}

export default SuccessfulPopup
