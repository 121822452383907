import {useReactiveVar} from '@apollo/client'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {previousCartIDsVar, restaurantSelectionTableTokens} from 'state/reactiveVariables'
import {remoteRestaurantSelectionToken} from 'utils/constants'
import {getCustomerDetails, getLatestOrderItem, getOtherOrdersCustomerDetails} from 'utils/order'

/**
 * Get customer details from the latest order item in the current cart or from
 * other orders made by the same customer.
 *
 * Data can be used to prefill customer details in the order form.
 */
const useCustomerDetails = () => {
  const {tableData, cartData, handledOrderData} = useRestaurantContext()
  const {token} = tableData?.getTable || {}
  const cartID = cartData?.getCart.id

  const restaurantSelectionToken = localStorage.getItem(remoteRestaurantSelectionToken)
  const otherTableTokens = useReactiveVar(restaurantSelectionTableTokens).filter((tableToken) => tableToken !== token)
  const previousCartIDsVars = useReactiveVar(previousCartIDsVar)
  const previousCartIDs = previousCartIDsVars.flatMap((id) => Object.values(id)).filter((id) => id !== null)

  const customerDetailsFromCurrentCart = getCustomerDetails(getLatestOrderItem(tableData, handledOrderData, cartID))
  let customerDetails = customerDetailsFromCurrentCart

  const customerDetailsFromPreviousCart = getOtherOrdersCustomerDetails(otherTableTokens, previousCartIDs)
  if (!customerDetails && restaurantSelectionToken && otherTableTokens.length) {
    // Get customer details from orders in other tables made by the same customer
    customerDetails = customerDetailsFromPreviousCart
  }

  return customerDetails
}

export default useCustomerDetails
