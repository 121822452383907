import {Checkbox, CheckboxGroup, Label, RadioButton, RadioButtonGroup} from '@s-group/design-system-components'
import styled from 'styled-components'

const RadioButtonGroupWrapper = RadioButtonGroup
const CheckboxGroupWrapper = CheckboxGroup
const RecommendedCheckboxLabel = Label

const RadioButtonWrapper = styled(RadioButton)`
  line-height: ${({theme}) => theme.spacing.medium};
  display: flex;

  svg {
    padding-top: ${({theme}) => theme.spacing.xxxxsmall};
  }
`

const RadioButtonLabel = styled.div`
  /* Intentionally left blank */
`

const RadioButtonLabelExtraInfo = styled.div`
  display: float;
  float: right;
`

const CheckboxButtonWrapper = styled(Checkbox)`
  line-height: ${({theme}) => theme.spacing.medium};
  display: flex;

  svg {
    padding-top: ${({theme}) => theme.spacing.xxxxsmall};
  }
`

const CheckboxLabel = styled.div`
  /* Intentionally left blank */
`

const CheckboxLabelExtraInfo = styled.div`
  display: float;
  float: right;
`

export {
  CheckboxButtonWrapper,
  CheckboxGroupWrapper,
  CheckboxLabel,
  CheckboxLabelExtraInfo,
  RadioButtonGroupWrapper,
  RadioButtonLabel,
  RadioButtonLabelExtraInfo,
  RadioButtonWrapper,
  RecommendedCheckboxLabel,
}
