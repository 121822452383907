import {Customer} from '@marketplace/shared-lib/graphql/graphql'
import AttachBonusCard from './AttachBonusCard'
import IncomingBonus from './IncomingBonus'

type CustomerData = NonNullable<Customer>

type BonusInformationProps = {
  bonusInformation?: CustomerData['benefitsAndPurchases']
  hasBonusCard: boolean
}

const BonusInformation = ({bonusInformation, hasBonusCard}: BonusInformationProps) => {
  if (!hasBonusCard) {
    return <AttachBonusCard />
  }
  return <IncomingBonus bonusInformation={bonusInformation} />
}

export default BonusInformation
