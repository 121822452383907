import {PortionOption, PortionOptionSectionType} from '@marketplace/shared-lib/graphql/graphql'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Text} from '@s-group/design-system-components'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import CheckBoxButton from 'Components/SelectionBox/CheckBoxButton'
import SelectionBoxGroup from 'Components/SelectionBox/SelectionBoxGroup'
import {RecommendedCheckboxLabel} from 'Components/SelectionBox/SelectionBoxStyles'
import {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'
import {spacing} from 'theme'
import {PortionSelectedIds} from 'types/productCardTypes'
import {getPortionPrice} from 'utils/portionPriceUtils'
import useTriggerEventNew from '../../hooks/useTriggerEventNew'
import useTriggerViewPromotionEvent from '../../hooks/useTriggerViewPromotionEvent'
import {EventItemContext} from '../../types/analyticsTypesNew'
import {mapPortionToEventItem} from '../../utils/analytics'
import {
  DietTagsAbbreviations,
  OptionsWrapper,
  RecommendedOptionSectionName,
  RecommendedOptionsWrapper,
  RecommendedPortionDescription,
  RecommendedPortionInfo,
  RecommendedPortionWrapper,
  RecommendedProductPrice,
  SectionTitle,
  SizeLabel,
} from './ProductStyles'
import {
  PortionWithMenuID,
  getButtonType,
  getPortionSelections,
  getSelectedPortionOptions,
  getSelectedSizeLabel,
  useGetCommaSeparatedDietTagsAbbreviations,
} from './productCardUtils'

export type ProductRecommendationsProps = {
  recommendationsHeader: string
  recommendations: PortionWithMenuID[]
  selectedPortions: PortionWithMenuID[]
  setSelectedPortions: Dispatch<SetStateAction<PortionWithMenuID[]>>
  portionsSelections: PortionSelectedIds[]
  handleOptionChangeRadio: (portion: PortionWithMenuID, optionSectionId: string, optionId: string) => void
  handleOptionChangeCheckbox: (
    portion: PortionWithMenuID,
    optionSectionId: string,
    optionId: string,
    maxCount?: number | null
  ) => void
  analytics: EventItemContext
}

const ProductRecommendations = ({
  recommendationsHeader,
  recommendations,
  selectedPortions,
  setSelectedPortions,
  portionsSelections,
  handleOptionChangeRadio,
  handleOptionChangeCheckbox,
  analytics,
}: ProductRecommendationsProps) => {
  const {i18n} = useTranslation('order')
  const locale = i18n.language
  const getDietTags = useGetCommaSeparatedDietTagsAbbreviations()
  const triggerEventNew = useTriggerEventNew()

  useTriggerViewPromotionEvent(recommendations, analytics)

  if (!recommendations.length) return null

  return (
    <>
      <OptionsWrapper>
        <SectionTitle variant='display' sizing='xxxxsmall' weight='bold' data-testid='recommendations-section-title'>
          {recommendationsHeader}
        </SectionTitle>
      </OptionsWrapper>
      {recommendations.map((recommendedPortion) => {
        const {id: portionId, name, description, portionOptionSections, diet} = recommendedPortion
        const portionName = getLocalized(name, locale)
        const portionDescription = getLocalized(description, locale)
        const commaSeparatedDietTagsAbbreviations = getDietTags(diet)

        const isChecked = !!selectedPortions.find((item) => item.id === portionId)
        const portionSelections = getPortionSelections(portionId, portionsSelections)
        const sizeLabel = getLocalized(getSelectedSizeLabel(portionOptionSections, portionSelections), locale)
        const {totalNormalPrice, totalCoopMemberPrice} = getPortionPrice(recommendedPortion, portionSelections)

        return (
          <OptionsWrapper key={portionId} data-testid={`recommendation-container-${portionId}`}>
            <RecommendedPortionWrapper data-testid={`recommendation-wrapper-${portionId}`}>
              <CheckBoxButton
                id={`recommendation-checkbox-${portionId}`}
                type='checkbox'
                value={portionId}
                name={portionName}
                checked={isChecked}
                onChange={({target}) => {
                  if (target.checked) {
                    const portionItem = getSelectedPortionOptions(recommendedPortion, portionSelections)
                    if (!portionItem) return
                    setSelectedPortions((prevState) => [...prevState, portionItem])
                    const index = recommendations.findIndex(
                      (recommendation) => recommendation.id === recommendedPortion.id
                    )
                    triggerEventNew?.({
                      event: 'select_promotion',
                      ecommerce: {
                        items: [{...mapPortionToEventItem(recommendedPortion), index, ...analytics}],
                      },
                    })
                  } else {
                    setSelectedPortions((prevState) => [...prevState.filter((portion) => portion.id !== portionId)])
                  }
                }}
              >
                <RecommendedPortionInfo>
                  <RecommendedCheckboxLabel
                    htmlFor={`recommendation-checkbox-${portionId}`}
                    data-testid={`recommendation-label-${portionId}`}
                  >
                    <Text variant='heading' sizing='xxsmall' weight='medium'>
                      {portionName}
                    </Text>
                    {commaSeparatedDietTagsAbbreviations && (
                      <DietTagsAbbreviations variant='heading' sizing='xxsmall' paddingLeft={spacing.xxsmall}>
                        {`(${commaSeparatedDietTagsAbbreviations})`}
                      </DietTagsAbbreviations>
                    )}
                  </RecommendedCheckboxLabel>
                  {portionDescription && (
                    <RecommendedPortionDescription
                      variant='body'
                      sizing='small'
                      lineClamp={!isChecked}
                      data-testid={`recommendation-description-${portionId}`}
                    >
                      {portionDescription}
                    </RecommendedPortionDescription>
                  )}
                  {portionOptionSections.map((optionSection) => {
                    if (!optionSection || !isChecked || !portionSelections) return null
                    const {id: optionSectionId, portionOptions, type, minCount, maxCount} = optionSection

                    // minCount and maxCount can be undefined
                    const definedButtonType = getButtonType(type, minCount, maxCount)
                    const optionSectionNameTranslated = getLocalized(optionSection.name, locale)
                    const selectedOptionIds = portionSelections
                      .filter(({selectedSectionId}) => selectedSectionId === optionSectionId)
                      .flatMap((ids) => ids.selectedOptionIds)

                    return (
                      <RecommendedOptionsWrapper
                        key={`${optionSectionId}-wrapper`}
                        data-testid={`recommendation-option-section-${optionSectionId}`}
                      >
                        {optionSectionNameTranslated && (
                          <RecommendedOptionSectionName
                            variant='heading'
                            sizing='xxsmall'
                            weight='medium'
                            data-testid='size-options-title'
                          >
                            {optionSectionNameTranslated}
                          </RecommendedOptionSectionName>
                        )}
                        <SelectionBoxGroup
                          compact
                          isSize={type === PortionOptionSectionType.SIZE}
                          key={optionSectionId}
                          portionId={portionId}
                          buttonType={definedButtonType}
                          options={portionOptions as PortionOption[]}
                          optionSectionId={optionSectionId}
                          selectedIds={selectedOptionIds}
                          onChange={({target: {value: optionId}}) => {
                            if (definedButtonType === 'checkbox') {
                              handleOptionChangeCheckbox(recommendedPortion, optionSectionId, optionId, maxCount)
                            } else {
                              handleOptionChangeRadio(recommendedPortion, optionSectionId, optionId)
                            }
                          }}
                        />
                      </RecommendedOptionsWrapper>
                    )
                  })}
                  <RecommendedProductPrice>
                    {sizeLabel && (
                      <SizeLabel variant='body' sizing='small' data-testid={`recommendation-size-label-${portionId}`}>
                        {sizeLabel}
                      </SizeLabel>
                    )}
                    <Text variant='body' sizing='medium' weight={!totalCoopMemberPrice ? 'bold' : 'regular'}>
                      {formatPrice({price: totalNormalPrice})}
                    </Text>
                    <CoopMemberPrice logoSize='1rem' coopMemberPrice={totalCoopMemberPrice} />
                  </RecommendedProductPrice>
                </RecommendedPortionInfo>
              </CheckBoxButton>
            </RecommendedPortionWrapper>
          </OptionsWrapper>
        )
      })}
    </>
  )
}

export default ProductRecommendations
