import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import Feedback from './Feedback'
import {FooterActionsWrapper} from './FeedbackStyles'

const FooterFeedbackLink = ({tableType}: {tableType?: TableType}) => (
  <FooterActionsWrapper>
    <Feedback tableType={tableType} />
  </FooterActionsWrapper>
)

export default FooterFeedbackLink
