import {useEffect, useState} from 'react'
import {isQa, isProduction} from './envUtils'

const restaurantsWithPrinters = [
  '420', // Pizza & Buffa Prisma, Iisalmi
  '424', // Pizza & Buffa Prisma, Kuopio
  '426', // Pizza & Buffa Prisma Mikkola, Pori
  '123123', // Esittelyravintola
]

// Enabled in non-production environments and for specific restaurants in production
const isPrintingEnabled = (restaurantID: string) => {
  if (!isProduction) return true
  if (isProduction && restaurantID) return restaurantsWithPrinters.includes(restaurantID)
  return false
}

const isLoginEnabled = () => {
  return !isProduction && !isQa
}

type FeatureFlags = {
  isPrintingEnabled: (restaurantId: string) => boolean
  isLoginEnabled: () => boolean
}

const defaults: FeatureFlags = {
  isPrintingEnabled: () => false,
  isLoginEnabled: () => false,
}

// this is async to allow for dynamic feature flags in the future, like from LaunchDarkly
const getFeatureFlags = async (): Promise<FeatureFlags> => ({
  isPrintingEnabled,
  isLoginEnabled,
})

const useFeatureFlags = (): FeatureFlags => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaults)

  useEffect(() => {
    getFeatureFlags().then(setFeatureFlags)
  }, [])

  return featureFlags
}

export default useFeatureFlags
