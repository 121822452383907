import {useEffect, useRef} from 'react'
import {triggerEvent} from 'hooks/useDataLayer'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import {AnalyticsEvents, AnalyticsActions} from 'types/analyticsTypes'
import {mapPortionToEventItem} from 'utils/analytics'
import useRestaurantContext from './useRestaurantContext'

const useCartAnalytics = () => {
  const {tableData, cartData, restaurantData} = useRestaurantContext()
  const triggerEventNew = useTriggerEventNew()
  const viewCartEventTriggered = useRef(false)
  const {tableType, tableID} = tableData?.getTable || {}
  const products = cartData?.getCart.items
  const productTotals = cartData?.getCart.total
  const restaurantName = restaurantData?.getRestaurant.name.fi

  useEffect(() => {
    if (restaurantName && tableID) {
      triggerEvent({
        event: AnalyticsEvents.MAKE_ORDER,
        action: AnalyticsActions.SUMMARY_OPEN,
        restaurant_name: restaurantName,
        table: tableID,
        table_type: tableType,
      })
    }
  }, [tableID, restaurantName, tableType])

  useEffect(() => {
    if (productTotals && products && triggerEventNew && !viewCartEventTriggered.current) {
      triggerEventNew({
        event: 'view_cart',
        ecommerce: {
          value: productTotals.normal / 100,
          currency: 'EUR',
          items: products.map((product) => ({...mapPortionToEventItem(product.portion), quantity: product.quantity})),
        },
      })
      // Make sure we don't trigger a new view_cart event when mutating the cart
      viewCartEventTriggered.current = true
    }
  }, [productTotals, products, triggerEventNew])
}

export default useCartAnalytics
