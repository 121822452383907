import {useReactiveVar} from '@apollo/client'
import {useGetRestaurantQuery} from '@marketplace/shared-lib/graphql/graphql'
import {parseSiteId} from '@marketplace/shared-lib/src/utils/idParser'
import {useEffect} from 'react'
import * as Sentry from '@sentry/react'
import {customerFullAge, restaurantID} from 'state/reactiveVariables'
import {getCustomerFullAgeFilteredMenus} from 'utils/menuUtils'
import {SentryTagKeys} from 'utils/sentryUtils'

type UseRestaurantDataOptions = {
  withMenus: boolean
}

const useRestaurantData = (options?: Partial<UseRestaurantDataOptions>) => {
  const currentRestaurantID = useReactiveVar(restaurantID)
  const {isRestaurantId} = parseSiteId(currentRestaurantID)
  const currentCustomerFullAge = useReactiveVar(customerFullAge)
  const withMenusOption: boolean = typeof options?.withMenus === 'boolean' ? options.withMenus : true

  Sentry.setTag(SentryTagKeys.AgeConsent, !!currentCustomerFullAge)

  // define when to fetch; we don't want to try to fetch if the id is not
  // defined or it is not a restaurant ID (i.e. it is a hotel ID)
  const shouldFetch = currentRestaurantID && isRestaurantId

  const {data, loading} = useGetRestaurantQuery({
    variables: {
      input: {restaurantID: currentRestaurantID},
      withMenus: withMenusOption,
    },
    skip: !shouldFetch,
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      // Should we direct to something went wrong page if restaurantData fails?
      console.log(error)
    },
  })

  useEffect(() => {
    if (!data?.getRestaurant) return
    // add tags to Sentry for filtering and searching
    Sentry.setTag(SentryTagKeys.RestaurantId, data.getRestaurant.id)
    Sentry.setTag(SentryTagKeys.RestaurantName, data.getRestaurant.name.fi)
    // Add restaurant to Sentry context for easier debugging.
    // Let's remove menus from the context to avoid sending too much data.
    Sentry.setContext('restaurant', {
      ...data?.getRestaurant,
      menus: '[TRUNCATED]',
    })
  }, [data?.getRestaurant])

  // customer has answered no to being of full age
  if (currentCustomerFullAge === false) {
    if (data?.getRestaurant.menus) {
      const menusWithoutAlcohol = getCustomerFullAgeFilteredMenus(data.getRestaurant.menus, false)

      return {
        data: {...data, getRestaurant: {...data.getRestaurant, menus: menusWithoutAlcohol}},
        loading,
      }
    }
    return {data, loading}
  }

  return {data, loading}
}

export default useRestaurantData
