export const isPositiveInteger = (value: number) => Number.isInteger(value) && value > 0

export const isInteger = (value: number) => Number.isInteger(value)

export const isInRange = (value: number, min: number, max: number) => {
  if (typeof value !== 'number') {
    console.error('Value is not a number:', value)
    return false
  }
  return value >= min && value <= max
}

/**
 * validateCurrencyString
 * @param value amount in cents
 * @param currency this is the currency code, e.g. EUR
 * @returns string with currency symbol and two decimals
 */
export const validateCurrencyCode = (currency: string) => {
  // Currency string should be 3 letters long, provide fallback to EUR if not: https://en.wikipedia.org/wiki/ISO_4217
  if (currency && currency.match(/^[A-Z]{3}$/i)) {
    return currency
  }
  return 'EUR'
}

/**
 * formatCurrency
 * @param amountInCents should not contain any decimals
 * @param currency should be valid currency, if not valid or undefined, default to EUR
 * @returns currency with 2 decimals and € sign e.g. 20,00 €
 */
export const formatCurrency = (amountInCents: number, currency: string = 'EUR'): string => {
  const value = amountInCents / 100

  return new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: validateCurrencyCode(currency),
  }).format(value)
}

/**
 * formatPercentage
 * @param percentage can contain decimals
 * @returns percentage with max. 2 decimals and % sign e.g. 1,25 %
 */
export const formatPercentage = (percentage: number): string => {
  const percentageinFraction = percentage / 100
  return new Intl.NumberFormat('fi-FI', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(percentageinFraction)
}
