import {CartItemFieldsFragment, ContainsAlcohol} from '@marketplace/shared-lib/graphql/graphql'
import {countCartItems} from '@marketplace/shared-lib/src/utils/cartUtils'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import useRestaurantContext from 'hooks/useRestaurantContext'

type UseCartUtilsReturnType = {
  cartItems: CartItemFieldsFragment[]
  /** Total price of all items in the cart as a formatted component. */
  totalPrice: JSX.Element
  /** Total number of items in the cart. */
  totalQuantity: number
  /**
   * Whether the current cart order is disabled due to kitchen being closed.
   *
   * That is, the cart contains at least one dish and the kitchen is closed.
   *
   * If order contains only drinks, then the order is not blocked regardless of
   * kitchen status.
   */
  isFoodOrderBlockedDueToKitchenClosed: boolean
  orderContainsAlcohol: boolean
}

/**
 * Helper hook for some common cart-related values.
 */
const useCartUtils = (): UseCartUtilsReturnType => {
  const {cartData, restaurantFeatures} = useRestaurantContext()

  const cartItems = cartData?.getCart.items ?? []
  const productTotals = cartData?.getCart.total
  const totalPrice = formatPrice({price: productTotals?.normal ?? 0})
  const totalQuantity = countCartItems(cartItems ?? [])

  const kitchenClosed = restaurantFeatures?.kitchenClosed ?? false
  const isFoodOrderBlockedDueToKitchenClosed = kitchenClosed && cartItems.some((item) => item.portion.type === 'DISH')

  const orderContainsAlcohol = cartItems.some(({portion}) => portion.containsAlcohol === ContainsAlcohol.YES)

  return {
    cartItems,
    totalPrice,
    totalQuantity,
    isFoodOrderBlockedDueToKitchenClosed,
    orderContainsAlcohol,
  }
}

export default useCartUtils
