import {useTranslation} from 'react-i18next'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {Description, Title} from './OrderStyles'

type SummaryHeaderProps = {
  tableType?: TableType
  cartEmpty?: boolean
}

const SummaryHeader = ({tableType, cartEmpty}: SummaryHeaderProps) => {
  const {t} = useTranslation('order')

  const descriptionTranslationKey = (() => {
    if (cartEmpty) return 'summary.cartEmpty'
    if (tableType === TableType.PICKUP || tableType === TableType.ROOM) return 'summary.descriptionPickupOrRoomService'
    return 'summary.description'
  })()

  return (
    <>
      <Title variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase' data-testid='summary-title'>
        {t('summary.title')}
      </Title>
      <Description variant='body' sizing='small' data-testid='summary-description-cart-empty'>
        {t(descriptionTranslationKey)}
      </Description>
    </>
  )
}

export default SummaryHeader
