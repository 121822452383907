import {CartItemFieldsFragment, UpdateCartAction} from '@marketplace/shared-lib/graphql/graphql'
import {isDeliveryPortion} from '@marketplace/shared-lib/src/utils/cartUtils'
import {isRestaurantKitchenOpen} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import ProductSummaryCard from 'Components/Product/ProductSummaryCard'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useUpdateCartMutation from 'hooks/useUpdateCartMutation'
import {EventItemContext} from 'types/analyticsTypesNew'
import {ItemWrapper, ItemsContainer} from './SummaryStyles'

const SummaryItems = ({cartItems}: {cartItems: CartItemFieldsFragment[]}) => {
  const {restaurantData, restaurantFeatures} = useRestaurantContext()
  const {cartModify} = useUpdateCartMutation()

  const isKitchenOpen = isRestaurantKitchenOpen({
    kitchenClosed: restaurantFeatures?.kitchenClosed,
    kitchenOpenAutomatic: restaurantFeatures?.kitchenOpenAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
  })

  return (
    <ItemsContainer>
      {cartItems
        .filter((cur) => !isDeliveryPortion(cur.portion))
        .map((cartItem, index) => {
          const {portion, additionalInformation: portionAdditionalInformation, menuID} = cartItem
          const analytics: EventItemContext = {
            index,
            item_list_name: 'Summary',
          }
          const items = [
            {
              portion,
              menuID: menuID === null ? undefined : menuID,
              additionalInformation: portionAdditionalInformation,
              analytics,
            },
          ]
          return (
            <ItemWrapper key={`${portion.id}${index}`}>
              <ProductSummaryCard
                cartItem={cartItem}
                onAddItem={() => cartModify({items})}
                onRemoveItem={() =>
                  cartModify({
                    items,
                    action: UpdateCartAction.SUBTRACT,
                  })
                }
                onRemoveItemComment={() =>
                  cartModify({
                    items,
                    action: UpdateCartAction.CLEAR_COMMENT,
                  })
                }
                kitchenClosed={!isKitchenOpen}
              />
            </ItemWrapper>
          )
        })}
    </ItemsContainer>
  )
}

export default SummaryItems
