import {PortionFieldsFragment} from '@marketplace/shared-lib/graphql/graphql'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {Text} from '@s-group/design-system-components'
import {IconNavigationArrowRight} from '@s-group/design-system-icons'
import {useTranslation} from 'react-i18next'
import {spacing} from 'theme'
import {getLowestPortionPrice} from '../../utils/portionPriceUtils'
import CoopMemberPrice from '../Price/CoopMemberPrice'
import {
  AddToCartButton,
  ProductRecommendationDietTags,
  ProductRecommendationPrice,
  ProductRecommendationTitle,
  WelcomeRecommendationBottomRow,
  WelcomeRecommendationCardWrapper,
  WelcomeRecommendationDescription,
} from './ProductRecommendationCardStyles'

interface ProductRecommendationCardProps {
  portion: PortionFieldsFragment
  onAddItem: () => void
  loading: boolean
  ctaText?: string
}

const WelcomeRecommendationProductCard = ({portion, onAddItem, loading, ctaText}: ProductRecommendationCardProps) => {
  const {i18n, t} = useTranslation('order')
  const locale = i18n.language
  const title = getLocalized(portion.name, locale)
  const description = getLocalized(portion.description, locale)
  const buttonLabel: string = ctaText ?? t('menu.add')
  const buttonAriaLabel: string = ctaText ?? t('menu.addToOrder')
  const dietTags: string = portion.diet
    .map((dietTag) => t(`menu.dietTags.abbreviations.${dietTag}`))
    .filter((dietTagText) => dietTagText) // filter out empty strings
    .join(', ')
  const {lowestNormal, lowestCoop} = getLowestPortionPrice(portion)

  return (
    <WelcomeRecommendationCardWrapper data-testid={`product-recommendation-card-${portion.id}`}>
      <ProductRecommendationTitle
        data-testid='product-recommendation-card-title'
        variant='heading'
        sizing='xxsmall'
        weight='medium'
      >
        {title}
        {dietTags !== '' && <ProductRecommendationDietTags>{` (${dietTags})`}</ProductRecommendationDietTags>}
      </ProductRecommendationTitle>
      {description && (
        <WelcomeRecommendationDescription
          variant='body'
          sizing='small'
          data-testid='product-recommendation-card-description'
        >
          {description}
        </WelcomeRecommendationDescription>
      )}
      <WelcomeRecommendationBottomRow>
        <ProductRecommendationPrice>
          <Text
            variant='body'
            sizing='medium'
            weight={!lowestCoop ? 'bold' : 'regular'}
            data-testid='product-recommendation-card-normal-price'
          >
            {formatPrice({price: lowestNormal})}
          </Text>
          <CoopMemberPrice
            textSize='medium'
            testId='product-recommendation-card-coop-price'
            logoSize='1rem'
            coopMemberPrice={lowestCoop}
          />
        </ProductRecommendationPrice>
        <AddToCartButton
          rounding='small'
          color='neutral'
          variant='filled'
          sizing='xsmall'
          onClick={onAddItem}
          disabled={loading}
          aria-label={buttonAriaLabel}
          icon={<IconNavigationArrowRight />}
          iconPos='after'
          $paddingSide={spacing.small}
        >
          {buttonLabel}
        </AddToCartButton>
      </WelcomeRecommendationBottomRow>
    </WelcomeRecommendationCardWrapper>
  )
}

export default WelcomeRecommendationProductCard
