import {useTranslation} from 'react-i18next'
import {SummaryTotalContainer, SummaryTotalRow, SummaryTotalText} from './SummaryStyles'

const SummaryTotal = ({totalPrice}: {totalPrice: JSX.Element}) => {
  const {t} = useTranslation('order')

  return (
    <SummaryTotalContainer>
      <SummaryTotalRow>
        <SummaryTotalText data-testid='summary-order-total' variant='body' sizing='small' weight='bold'>
          {t('summary.orderTotal')}
        </SummaryTotalText>
        <SummaryTotalText data-testid='summary-total-price' variant='body' sizing='small' weight='bold'>
          {totalPrice}
        </SummaryTotalText>
      </SummaryTotalRow>
    </SummaryTotalContainer>
  )
}

export default SummaryTotal
