import SharedModal from '@marketplace/shared-lib/src/UI/modal'
import {useHasUserInteracted} from '@s-group/react-usercentrics'
import {useContext, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import ModalContext from 'state/ModalContext'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {BillOptionDialog} from '../BillOptionDialog'
import CallWaiterConfirmation from '../CallWaiterConfirmation/CallWaiterConfirmation'
import {ErrorPopup, SuccessfulPopup} from '../Popup'
import Welcome from '../Welcome/Welcome'
import {ModalState} from './modalTypes'

const closeAfterTimeoutStates = [
  ModalState.WaiterCallCancelled,
  ModalState.BillRequested,
  ModalState.WaiterCalled,
  ModalState.RequestBillCancelled,
]

const Modal = () => {
  const {t} = useTranslation('remotePage')
  const {modalState, setModalState} = useContext(ModalContext)
  const modalTimer = useRef<ReturnType<typeof setTimeout> | undefined>()

  // close some of the modals after interval
  useEffect(() => {
    if (closeAfterTimeoutStates.includes(modalState)) {
      modalTimer.current = setTimeout(() => {
        setModalState(ModalState.Closed)
      }, 3000)
    }
    return () => {
      if (modalTimer.current) {
        clearTimeout(modalTimer.current)
      }
    }
  }, [modalState, setModalState])

  // Disable default shared Modal closing behavior on Welcome dialog,
  // forces user to navigate through age dialog or recommendation buttons
  const disableDefaultOnClose = modalState === ModalState.WelcomeDialog

  const isCookieConsentNoted = useHasUserInteracted()
  const isOpen =
    (isCookieConsentNoted && modalState === ModalState.WelcomeDialog) ||
    (modalState !== ModalState.Closed && modalState !== ModalState.WelcomeDialog)

  return (
    <SharedModal
      isOpen={isOpen}
      data-testid='modal'
      onClose={disableDefaultOnClose ? undefined : () => setModalState(ModalState.Closed)}
    >
      {modalState === ModalState.WelcomeDialog && <Welcome />}
      {modalState === ModalState.OrderFinished && (
        <SuccessfulPopup message={t('orderFinished')} testId='order-finished-popup' buttonText={t('ok')} />
      )}
      {modalState === ModalState.PickupOrderFinished && (
        <SuccessfulPopup
          message={t('pickupOrderFinished')}
          bodyText={t('pickupOrderFinishedDescription', {delay: 20})}
          testId='pickup-order-finished-popup'
          buttonText={t('ok')}
          feedbackText={t('pickupOrderFeedbackDescription')}
          tableType={TableType.PICKUP}
        />
      )}
      {modalState === ModalState.WaiterCalled && (
        <SuccessfulPopup message={t('waiterCalled')} testId='waiter-called-popup' />
      )}
      {modalState === ModalState.WaiterCallCancelled && (
        <SuccessfulPopup message={t('waiterCallCancelled')} testId='waiter-call-cancelled-popup' />
      )}
      {modalState === ModalState.RequestBillCancelled && (
        <SuccessfulPopup message={t('requestBillCancelled')} testId='request-bill-cancelled-popup' />
      )}
      {modalState === ModalState.BillRequested && (
        <SuccessfulPopup message={t('billRequested')} testId='bill-requested-popup' />
      )}
      {modalState === ModalState.CallWaiterConfirmationStep && <CallWaiterConfirmation />}
      {modalState === ModalState.BillOptions && <BillOptionDialog />}
      {modalState === ModalState.TableDisabled && (
        <ErrorPopup message={t('tableDisabledError')} testId='table-disabled-popup' />
      )}
      {modalState === ModalState.Error && <ErrorPopup message={t('callForWaiterFailed')} testId='error-popup' />}
    </SharedModal>
  )
}

export default Modal
