import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import * as Sentry from '@sentry/react'
import {Language} from '@marketplace/shared-lib/src/types/language'
import {SentryTagKeys} from 'utils/sentryUtils'
import enLogin from './locales/en/login.json'
import enOrder from './locales/en/order.json'
import enRemotePage from './locales/en/remotePage.json'
import enWelcome from './locales/en/welcome.json'
import fiLogin from './locales/fi/login.json'
import fiOrder from './locales/fi/order.json'
import fiRemotePage from './locales/fi/remotePage.json'
import fiWelcome from './locales/fi/welcome.json'
import svLogin from './locales/sv/login.json'
import svOrder from './locales/sv/order.json'
import svRemotePage from './locales/sv/remotePage.json'
import svWelcome from './locales/sv/welcome.json'

const resources = {
  fi: {remotePage: fiRemotePage, order: fiOrder, welcome: fiWelcome, login: fiLogin},
  en: {remotePage: enRemotePage, order: enOrder, welcome: enWelcome, login: enLogin},
  sv: {remotePage: svRemotePage, order: svOrder, welcome: svWelcome, login: svLogin},
}

// exported for tests
export const defaultLanguage = Language.FI

const initialLanguage = window?.localStorage.getItem('i18nextLng') || defaultLanguage

Sentry.setTag(SentryTagKeys.Language, initialLanguage)

i18n.use(initReactI18next).init({
  resources,
  lng: initialLanguage,
  fallbackLng: defaultLanguage,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  // log error about missing translation; Sentry will catch console.errors
  saveMissing: true,
  missingKeyHandler: (_, namespace, key) => {
    console.error(`Missing translation for key "${namespace}:${key}"`)
  },
})

export default i18n
