import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useCartAnalytics from 'hooks/useCartAnalytics'
import SummaryRoomService from './SummaryRoomService'
import SummaryTakeaway from './SummaryTakeaway'
import SummaryCartEmpty from './SummaryCartEmpty'
import SummaryDefault from './SummaryDefault'

/**
 * Wrapper component for different summary/cart views.
 */
const Summary = () => {
  useCartAnalytics()
  const {tableData, cartData} = useRestaurantContext()
  const {tableType} = tableData?.getTable || {}
  const products = cartData?.getCart.items

  if (!products || products?.length === 0) {
    return <SummaryCartEmpty />
  }

  switch (tableType) {
    case TableType.ROOM:
      return <SummaryRoomService />
    case TableType.PICKUP:
      return <SummaryTakeaway />
    default:
      return <SummaryDefault />
  }
}

export default Summary
