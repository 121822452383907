import {resolveTimeFromHoursAndMinutes} from '@marketplace/shared-lib/src/utils/openingTimes'
import {Form, Text} from '@s-group/design-system-components'
import {colors} from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import PickupTimePicker from 'Components/PickupTimePicker/PickupTimePicker'
import {
  OptionAsap,
  PickupTimeDisclaimer,
  PickupTimeSelector,
  SpecificTimeSelector,
} from 'Components/PickupTimePicker/PickupTimePickerStyles'
import RadioButton from 'Components/SelectionBox/RadioButton'
import useCreateOrderMutation from 'hooks/useCreateOrderMutation'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {ExtendedCustomerDetails, validateDigits, validateText} from 'utils/order'
import useCustomerDetails from 'hooks/useCustomerDetails'
import useCartUtils from 'hooks/useCartUtils'
import CartRecommendations from './CartRecommendations'
import {Title} from './OrderStyles'
import SummaryItems from './SummaryItems'
import {
  InputField,
  InputFieldHelperText,
  InputFieldLabel,
  PickupPaymentInfo,
  PickupTimeChoiceLabel,
  SectionContainer,
  SummaryFormRow,
  SummaryWrapper,
  TermsLink,
} from './SummaryStyles'
import SummaryOrderSubmission from './SummaryOrderSubmission'
import SummaryHeader from './SummaryHeader'
import SummaryTotal from './SummaryTotal'

const minNameLen = 1
const minPhoneLen = 6

/**
 * Summary/cart for takeaway orders.
 *
 * See also:
 * - SummaryDefault.tsx
 * - SummaryRoomService.tsx
 */
const SummaryTakeaway = () => {
  const {t} = useTranslation('order')
  const {tableData, restaurantData} = useRestaurantContext()
  const {token, restaurantID, tableType, tableID} = tableData?.getTable || {}
  const restaurantName = restaurantData?.getRestaurant.name.fi
  const restaurantPhone = restaurantData?.getRestaurant.contact.primaryPhone?.number
  const {cartItems, totalPrice, totalQuantity, isFoodOrderBlockedDueToKitchenClosed} = useCartUtils()

  // use previous customer details as default values
  const customerDetails = useCustomerDetails()
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    phoneNumber: initialPhoneNumber,
  } = customerDetails || {}

  const setOrderProps = {
    restaurantID,
    token,
    restaurantName,
    tableID,
    tableType,
  }
  const {submitOrder, loading: submitOrderLoading} = useCreateOrderMutation(setOrderProps)

  const [nameValid, setNameValid] = useState(false)
  const [lastNameValid, setLastNameValid] = useState(false)
  const [nameVisited, setNameVisited] = useState(false)
  const [lastNameVisited, setLastNameVisited] = useState(false)
  const [phoneVisited, setPhoneVisited] = useState(false)
  const [phoneValid, setPhoneValid] = useState(false)
  const [roomServiceCustomerDetails, setRoomServiceCustomerDetails] = useState<ExtendedCustomerDetails>({
    firstName: initialFirstName,
    lastName: initialLastName,
    phoneNumber: initialPhoneNumber,
    selectedPickupTime: 'asap',
    selectedTime: undefined,
  })

  const {firstName, lastName, phoneNumber, selectedPickupTime, selectedTime} = roomServiceCustomerDetails ?? {}

  const handleChange = (key: keyof ExtendedCustomerDetails, value: string | boolean) =>
    setRoomServiceCustomerDetails({...roomServiceCustomerDetails, [key]: value})

  const validTime =
    (selectedTime &&
      selectedTime !== t('summary.tomorrowOrLater') &&
      // saves time in ISO8601 format YYYY-MM-DDTHH:MM:SS.000Z, for example '2006-01-02T15:04:03.321Z'.
      new Date(resolveTimeFromHoursAndMinutes({time: selectedTime})).toISOString()) ||
    undefined
  const isAsap = selectedPickupTime === 'asap'
  const validPickupTime = (!isAsap && !!validTime) || isAsap

  const missingTakeawayOrderDetails = !lastNameValid || !phoneValid || !nameValid || !validPickupTime

  const submitIsDisabled =
    totalQuantity === 0 ||
    submitOrderLoading ||
    !nameValid ||
    missingTakeawayOrderDetails ||
    isFoodOrderBlockedDueToKitchenClosed

  useEffect(() => {
    setNameValid(validateText(minNameLen, firstName))
    setLastNameValid(validateText(minNameLen, lastName))
    setPhoneValid(validateDigits(minPhoneLen, phoneNumber))
  }, [lastName, firstName, phoneNumber])

  const handleSubmitOrder = () => {
    submitOrder({
      nickName: firstName,
      lastName,
      phoneNumber,
      pickupTime: {
        asap: isAsap,
        time: (!isAsap && validTime) || undefined,
      },
    })
  }

  return (
    <SummaryWrapper>
      <SummaryHeader tableType={tableType} />
      <SummaryItems cartItems={cartItems} />
      <SummaryTotal totalPrice={totalPrice} />
      <CartRecommendations />
      <SectionContainer>
        <Title data-testid='summary-title' variant='heading' sizing='xxsmall' weight='medium'>
          {t('summary.orderInfo')}
        </Title>
      </SectionContainer>
      <Form>
        <SummaryFormRow>
          <InputFieldLabel sizing='small' htmlFor='firstNameContainer'>
            {t('summary.firstNameLabel')}
          </InputFieldLabel>
          <InputField
            value={firstName ?? ''}
            sizing='small'
            id='firstNameContainer'
            aria-required
            onChange={(e) => handleChange('firstName', e.target.value)}
            data-testid='first-name-input'
            onBlur={() => setNameVisited(true)}
            error={nameVisited && !nameValid}
            autoFocus
          />
          {nameVisited && !nameValid && (
            <InputFieldHelperText sizing='small' color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}>
              {t('summary.nameError', {length: minNameLen})}
            </InputFieldHelperText>
          )}
        </SummaryFormRow>
        <SummaryFormRow>
          <InputFieldLabel sizing='small' htmlFor='lastNameContainer'>
            {t('summary.lastNameLabel')}
          </InputFieldLabel>
          <InputField
            value={lastName ?? ''}
            sizing='small'
            id='lastNameContainer'
            aria-required
            onChange={(e) => handleChange('lastName', e.target.value)}
            data-testid='last-name-input'
            onBlur={() => setLastNameVisited(true)}
            error={lastNameVisited && !lastNameValid}
          />
          {lastNameVisited && !lastNameValid && (
            <InputFieldHelperText sizing='small' color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}>
              {t('summary.nameError', {length: minNameLen})}
            </InputFieldHelperText>
          )}
        </SummaryFormRow>
        <SummaryFormRow>
          <InputFieldLabel sizing='small' htmlFor='phoneNumberContainer'>
            {t('summary.phoneNumberLabel')}
          </InputFieldLabel>
          <InputField
            value={phoneNumber ?? ''}
            sizing='small'
            id='phoneNumberContainer'
            aria-required
            onChange={(e) => handleChange('phoneNumber', e.target.value)}
            data-testid='phone-number-input'
            onBlur={() => setPhoneVisited(true)}
            error={phoneVisited && !phoneValid}
          />
          {phoneVisited && !phoneValid && (
            <InputFieldHelperText sizing='small' color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}>
              {t('summary.phoneError', {length: minPhoneLen})}
            </InputFieldHelperText>
          )}
        </SummaryFormRow>
        <SummaryFormRow>
          <PickupTimeSelector>
            <PickupTimeChoiceLabel variant='heading' sizing='xxxsmall' weight='medium'>
              {t('summary.pickupTimeChoice')}
            </PickupTimeChoiceLabel>
            <RadioButton
              value='asap'
              label={
                <OptionAsap>
                  <Text variant='body' sizing='small' weight={selectedPickupTime === 'asap' ? 'medium' : 'regular'}>
                    {t('summary.asapTitle')}
                  </Text>
                  <Text variant='body' sizing='small'>
                    {t('summary.asapDescription', {delay: 20})}
                  </Text>
                </OptionAsap>
              }
              id='pickup-time-asap'
              name='pickup-time-asap'
              checked={selectedPickupTime === 'asap'}
              onChange={() => handleChange('selectedPickupTime', 'asap')}
              data-testid='pickup-time-asap'
            />
            <SpecificTimeSelector>
              <RadioButton
                value='specific'
                label={
                  <Text
                    data-testid='pickup-time-specific'
                    variant='body'
                    sizing='small'
                    weight={selectedPickupTime === 'specific' ? 'medium' : 'regular'}
                  >
                    {t('summary.specificTime')}
                  </Text>
                }
                id='pickup-time-specific'
                name='pickup-time-specific'
                checked={selectedPickupTime === 'specific'}
                onChange={() => handleChange('selectedPickupTime', 'specific')}
                data-testid='pickup-time-specific'
              />
              <PickupTimePicker setSelectedTime={handleChange} disabled={selectedPickupTime !== 'specific'} />
            </SpecificTimeSelector>
          </PickupTimeSelector>
          <span>
            {selectedTime === t('summary.tomorrowOrLater') && (
              <PickupTimeDisclaimer status='warning' sizing='small' alignment='left' close={false}>
                <Trans i18nKey='summary.futureTakeawayOrderNotification' values={{restaurantPhone}} ns='order' />
              </PickupTimeDisclaimer>
            )}
            <PickupPaymentInfo variant='body' sizing='small'>
              {t('summary.pickupOrderPaymentInfo')}
            </PickupPaymentInfo>
          </span>
        </SummaryFormRow>
        <SummaryOrderSubmission handleSubmitOrder={handleSubmitOrder} submitIsDisabled={submitIsDisabled} />
        <Text variant='body' sizing='small'>
          {t('summary.termsHeader')}{' '}
          <TermsLink href={t('summary.termsLink') ?? ''} target='_blank' rel='noreferrer'>
            {t('summary.termsLinkText')}
          </TermsLink>
        </Text>
      </Form>
    </SummaryWrapper>
  )
}

export default SummaryTakeaway
