import {IconUserProfile, IconUserLogout, IconNavigationLinkExternal} from '@s-group/design-system-icons'
import type {Dispatch, SetStateAction} from 'react'
import {Customer} from '@marketplace/shared-lib/graphql/graphql'
import {useTranslation} from 'react-i18next'
import {Button, LinkButton, Loader} from '@s-group/design-system-components'
import {isProduction} from '@marketplace/shared-lib/src/utils/envUtils'
import ConfirmPhoneNumber from 'Components/CustomerLoyalty/ConfirmPhoneNumber'
import BonusInformation from 'Components/CustomerLoyalty/BonusInformation'
import {CustomerName, FlexText, PopupRow} from './LoginPopUpStyles'
import LoginPopUpArea from './LoginPopUpArea'

type Props = {
  initiateLogout: () => void
  setVisible: Dispatch<SetStateAction<boolean>>
  customer: Customer
  loggingOutLoading: boolean
}

const userPortalLinkProd = 'https://s-kayttajatili.fi/sso'
const userPortalLinkTest = 'https://test.s-kayttajatili.fi/sso'
const linkToUserProfile = isProduction ? userPortalLinkProd : userPortalLinkTest

const LoggedInView = ({customer, setVisible, initiateLogout, loggingOutLoading}: Props) => {
  const {t} = useTranslation('login')
  const firstName = customer?.name?.first
  const lastName = customer?.name?.last
  const phoneNumberNotVerified = customer?.phone?.verified !== true
  const bonusInformation = customer?.benefitsAndPurchases
  const hasBonusCard = customer?.hasBonusCard ?? false

  return (
    <LoginPopUpArea setVisible={setVisible}>
      <PopupRow>
        <CustomerName tagName='h2' sizing='small' weight='regular'>
          {firstName} {lastName}
        </CustomerName>
      </PopupRow>
      {phoneNumberNotVerified && <ConfirmPhoneNumber />}
      <BonusInformation bonusInformation={bonusInformation} hasBonusCard={hasBonusCard} />
      <PopupRow $linkOnly>
        <LinkButton
          color='neutral'
          variant='plain'
          icon={<IconUserProfile />}
          compact
          sizing='small'
          href={linkToUserProfile}
          data-testid='link-to-user-profile'
        >
          <FlexText>{t('myInformation')}</FlexText>
          <IconNavigationLinkExternal />
        </LinkButton>
      </PopupRow>
      <PopupRow $noBottomBorder $linkOnly>
        <Button
          color='neutral'
          variant='plain'
          icon={loggingOutLoading ? <Loader type='circle' data-testid='logout-loader' /> : <IconUserLogout />}
          compact
          sizing='small'
          disabled={loggingOutLoading}
          aria-disabled={loggingOutLoading}
          data-testid='login-popup-logout-button'
          onClick={initiateLogout}
        >
          <FlexText>{t('logout')}</FlexText>
        </Button>
      </PopupRow>
    </LoginPopUpArea>
  )
}
export default LoggedInView
