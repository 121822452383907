import {useEffect} from 'react'
import {useReactiveVar} from '@apollo/client'
import * as Sentry from '@sentry/react'
import {useRestaurantFeaturesRemote} from '@marketplace/shared-lib/src/hooks'
import {parseSiteId} from '@marketplace/shared-lib/src/utils/idParser'
import {restaurantID} from 'state/reactiveVariables'

const useRestaurantFeatures = () => {
  const currentRestaurantID = useReactiveVar(restaurantID)
  const {isRestaurantId} = parseSiteId(currentRestaurantID)

  // define when to fetch; we don't want to try to fetch if the id is not
  // defined or it is not a restaurant ID (i.e. it is a hotel ID)
  const shouldFetch = currentRestaurantID && isRestaurantId

  const {data, loading, error} = useRestaurantFeaturesRemote({
    restaurantID: currentRestaurantID,
    skip: !shouldFetch,
  })

  const restaurantFeatures = data?.getRestaurantFeatures?.features

  // add restaurant features to Sentry context for easier debugging
  useEffect(() => {
    if (restaurantFeatures) {
      Sentry.setContext('restaurantFeatures', restaurantFeatures)
    }
  }, [restaurantFeatures])

  return {restaurantFeatures, restaurantFeaturesLoading: loading, restaurantFeaturesError: error}
}

export default useRestaurantFeatures
