import {useCallback, useState} from 'react'

/**
 * Helper hook for animating and closing modals with callback
 * @param setIsOpen will set modalClosed or open.
 * @param onClose callback function when closing modal happens.
 * @returns functions and values which enable animation and opacity of desired modal
 */
const useModalFeatures = ({setIsOpen, onClose}: {setIsOpen?: (isOpen: boolean) => void; onClose?: () => void}) => {
  const [opacity, setOpacity] = useState(0)
  const [animate, setAnimate] = useState(false)

  const afterOpen = useCallback(() => {
    setTimeout(() => {
      setOpacity(1)
      setAnimate(true)
    }, 50)
  }, [])

  const beforeClose = useCallback(() => {
    return new Promise((resolve) => {
      setOpacity(0)
      setAnimate(false)
      setTimeout(resolve, 150)
    })
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen?.(false)
    onClose?.()
  }, [onClose, setIsOpen])

  return {afterOpen, beforeClose, handleClose, opacity, animate}
}

export default useModalFeatures
