import {TZDate} from '@date-fns/tz'
import {
  ContainsAlcohol,
  DailyAvailabilityType,
  GetRestaurantQuery,
  PortionType,
  TableType,
} from '@marketplace/shared-lib/graphql/graphql'
import {
  resolveThisWeekday,
  resolveTimeFromHoursAndMinutes,
  weekdays,
} from '@marketplace/shared-lib/src/utils/openingTimes'
import {timezoneFinland} from '@marketplace/shared-lib/src/utils/timeUtils'
import {TFunction} from 'i18next'
import {Menu, MenuSections, Menus} from 'types/restaurantTypes'

type RestaurantMenus = NonNullable<GetRestaurantQuery['getRestaurant']['menus']>

export const includesAlcoholPortions = (menus: RestaurantMenus): boolean => {
  return menus.some((menu) =>
    menu.menuSections.some((menuSection) =>
      menuSection.portions.some((portion) => portion.containsAlcohol === ContainsAlcohol.YES)
    )
  )
}

export const getCustomerFullAgeFilteredMenus = (
  menus: RestaurantMenus,
  currentCustomerFullAge: boolean | undefined
): RestaurantMenus => {
  return menus.map((menu) => {
    const {menuSections, ...menuWithoutSections} = menu
    const filteredMenuSections = menuSections
      .map((menuSection) => {
        const {portions, ...menuSectionWithoutPortions} = menuSection

        // If customer is not of age, filter out portions containing alcohol
        const filteredPortions = portions.filter(
          (portion) => currentCustomerFullAge || portion.containsAlcohol !== ContainsAlcohol.YES
        )

        return {
          ...menuSectionWithoutPortions,
          portions: filteredPortions,
        }
      })
      .filter((menuSection) => menuSection.portions.length > 0) // filter out empty menuSections
    return {
      ...menuWithoutSections,
      menuSections: filteredMenuSections,
    }
  })
}

/**
 * Filter menus by table type.
 *
 * Use with Array.filter()
 *
 * @example
 * const filteredMenus = menus.filter(isMenuAvailableByTableType(TableType.BAR))
 */
export const isMenuAvailableByTableType =
  (tableType?: TableType) =>
  (menu: Menu): boolean => {
    if (!tableType) {
      return true
    }
    const {availability} = menu
    if (!availability.tableTypeAvailabilities?.length) {
      return true
    }
    return availability.tableTypeAvailabilities?.includes(tableType)
  }

/**
 * Filter menus by weekly availabilities based on current time.
 *
 * Use with Array.filter()
 *
 * @example
 * const filteredMenus = menus.filter(isMenuAvailableByWeeklyAvailabilities)
 */
export const isMenuAvailableByWeeklyAvailabilities = (menu: Menu): boolean => {
  const todayWeekday = resolveThisWeekday()
  const localTimeNow = TZDate.tz(timezoneFinland)
  const yesterdayWeekday = weekdays[(localTimeNow.getDay() + 6) % 7]

  const {weeklyAvailabilities} = menu.availability

  // menu is available by default if no weeklyAvailabilities
  if (!weeklyAvailabilities) {
    return true
  }

  // check if yesterday's menu is still valid
  const yesterdayAvailability = weeklyAvailabilities[yesterdayWeekday]
  if (
    yesterdayAvailability.type === DailyAvailabilityType.AVAILABLE_DURING_TIME_RANGE &&
    yesterdayAvailability.timeRange?.endNextDay
  ) {
    const localEndTime = resolveTimeFromHoursAndMinutes({
      time: yesterdayAvailability.timeRange.end,
      isTomorrow: true,
    })
    // menu is still valid
    return localEndTime >= localTimeNow
  }

  const todayAvailability = weeklyAvailabilities[todayWeekday]
  if (todayAvailability.type === DailyAvailabilityType.NOT_AVAILABLE) {
    return false
  }
  if (todayAvailability.type === DailyAvailabilityType.AVAILABLE_DURING_OPENING_HOURS) {
    return true
  }

  if (todayAvailability.timeRange) {
    const {start, end, endNextDay} = todayAvailability.timeRange

    // show menu if start or end time is missing
    if (!start || !end) return true

    const localStartTime = resolveTimeFromHoursAndMinutes({time: start})
    const localEndTime = resolveTimeFromHoursAndMinutes({time: end, isTomorrow: endNextDay})

    // has started and is still valid
    if (localStartTime <= localTimeNow && localEndTime >= localTimeNow && !endNextDay) {
      return true
    }

    // has started and is valid until tomorrow
    if (localTimeNow >= localStartTime && endNextDay) {
      return true
    }

    return false
  }

  return false
}

export const getMenusByID = (menus: Menus, menuId: string): Menus => {
  return (menus ?? []).filter(({id}) => id === menuId)
}

export const getMenusByAvailability = (menus: Menus, tableType?: TableType): Menus => {
  return (menus ?? []).filter(isMenuAvailableByWeeklyAvailabilities).filter(isMenuAvailableByTableType(tableType))
}

/**
 * Get menus with all the food portions filtered out.
 *
 * Useful when you want to show only drinks when kitchen is closed.
 */
export const getOnlyDrinksMenus = (menus: Menus): Menus => {
  return (menus ?? []).map((menu) => ({
    ...menu,
    menuSections: menu.menuSections.reduce((acc, section) => {
      const portions = section.portions.filter((portion) => portion.type === PortionType.DRINK)
      // add section if it has portions left
      if (portions.length > 0) {
        acc.push({...section, portions})
      }
      return acc
    }, [] as MenuSections),
  }))
}

export type ButtonType = 'radio' | 'checkbox'

export const resolveSelectionInstructions = (
  t: TFunction,
  definedButtonType: ButtonType,
  optionsCount: number,
  minCount?: number | null,
  maxCount?: number | null
) => {
  if (definedButtonType === 'checkbox') {
    // maxCount is set and lower than available options
    const hasMaxLimit = maxCount && maxCount < optionsCount
    if (minCount) {
      if (hasMaxLimit) {
        return maxCount === minCount
          ? t('menu.amountSelectionInstructions', {amount: minCount})
          : t('menu.minAndMaxSelectionInstructions', {minCount, maxCount})
      }
      return t('menu.minSelectionInstructions', {minCount})
    }
    if (hasMaxLimit) return t('menu.maxSelectionInstructions', {maxCount})
  }
  return undefined
}

/**
 * Internal name for section (or category) in the menu navigation.
 */
export const generateSectionInternalName = ({
  menuIndex,
  menuSectionIndex,
}: {
  menuIndex: number
  menuSectionIndex: number
}) => `menu-${menuIndex}-section-${menuSectionIndex}`
