import {RestaurantContextProps} from 'state/RestaurantContext'

export const resolveRestaurantPhoneNumber = (
  restaurantFeatures: NonNullable<RestaurantContextProps['restaurantFeatures']>,
  restaurantData: NonNullable<RestaurantContextProps['restaurantData']>
) => {
  /**
   * Use phone number set in Staff UI if it exsists.
   *
   * Otherwise use phone number from restaurant data
   * */
  const phoneNumber = restaurantFeatures?.phoneNumber
  const fallbackPhoneNumber = restaurantData.getRestaurant.contact?.primaryPhone?.number
  return phoneNumber || fallbackPhoneNumber
}
