import {ResourcesConfig} from '@aws-amplify/core'
import {currentEnv, graphqlEndpoint} from '@marketplace/shared-lib/src/utils/envUtils'

type Environment = NonNullable<NodeJS.ProcessEnv['VITE_APP_STAGE']>

type NonNullableAPIConfig = NonNullable<ResourcesConfig['API']>
type NonNullableGraphQLConfig = NonNullable<NonNullableAPIConfig['GraphQL']>
type NonNullableAuthConfig = NonNullable<ResourcesConfig['Auth']>

type Config = {
  appsync: ResourcesConfig & {
    API: {
      GraphQL: NonNullableGraphQLConfig
    }
    Auth: NonNullableAuthConfig
  }
  GTM: boolean
  COOKIE_CONSENT: string | undefined
  GTM_ID_FOR_USERCENTRICS: string
  // Some Sentry config is duplicated in vite.config.ts
  SENTRY_ENABLED: boolean
  SENTRY_ID_FOR_USERCENTRICS: string
  SENTRY_DSN: string
  COOKIE_EXPIRY_TIME_MS: number
  SHOW_PORTION_OPTION_NAME: boolean
}

const configs: Record<Environment, Config> = {}

configs.prod = {
  appsync: {
    API: {
      GraphQL: {
        region: 'eu-north-1',
        defaultAuthMode: 'iam',
        endpoint: 'https://api.marketplace.raflaamo.fi/graphql',
      },
    },
    Auth: {
      Cognito: {
        allowGuestAccess: true,
        identityPoolId: 'eu-north-1:ea99dd34-5bcf-4bdd-9c0a-ecb87984a020',
      },
    },
  },
  GTM: true,
  COOKIE_CONSENT: 'Yv591VkI5',
  // You can use window.UC_UI.getServicesBaseInfo() in the console to view
  // the Usercentrics service IDs.
  GTM_ID_FOR_USERCENTRICS: 'GkxomOJSe',
  COOKIE_EXPIRY_TIME_MS: 2 * 60 * 60 * 1000, // 2 hours
  SHOW_PORTION_OPTION_NAME: false,
  // TODO enable Sentry for prod once legal stuff is sorted out (Tietosuoja- ja tietoturvallisuusarviointi TSTT)
  // see https://s-ext.slack.com/archives/C044T1B6C3T/p1731406464201519
  SENTRY_ENABLED: false,
  SENTRY_ID_FOR_USERCENTRICS: 'TyWjZ913t',
  SENTRY_DSN: 'https://de53f8a8df5173ec72e6fd3a668008c1@o445732.ingest.us.sentry.io/4508284748890112',
}

if (currentEnv !== 'prod') {
  configs.dev = {
    ...configs.prod,
    appsync: {
      API: {
        GraphQL: {
          region: 'eu-north-1',
          defaultAuthMode: 'iam',
          endpoint: 'https://api.marketplace-dev.beta-test.raflaamo.fi/graphql',
        },
      },
      Auth: {
        Cognito: {
          allowGuestAccess: true,
          identityPoolId: 'eu-north-1:f6bf99e1-49c8-4a02-b87a-0dd01ee13ed4',
        },
      },
    },
    GTM: false,
    COOKIE_CONSENT: 'od4iU5RUI',
    GTM_ID_FOR_USERCENTRICS: 'Oc3JhRjIxCbRqz',
    SHOW_PORTION_OPTION_NAME: false,
    // let's disable Sentry in dev because we would probably get a lot of noise
    // from local development, and generally dev can be considered unstable
    SENTRY_ENABLED: false,
    SENTRY_ID_FOR_USERCENTRICS: 'IlOTcryRcjthKx',
    SENTRY_DSN: 'https://6b3419343e959cc6aa37865ef091812b@o445732.ingest.us.sentry.io/4508284741877760',
  }

  configs.qa = {
    ...configs.dev,
    appsync: {
      API: {
        GraphQL: {
          ...configs.dev.appsync.API.GraphQL,
          endpoint: 'https://api.marketplace-qa.beta-test.raflaamo.fi/graphql',
        },
      },
      Auth: {
        Cognito: {
          allowGuestAccess: true,
          identityPoolId: 'eu-north-1:9f7c6bf3-2bd8-469a-b779-4be4bfe81255',
        },
      },
    },
    GTM: false,
    COOKIE_CONSENT: 'od4iU5RUI',
    GTM_ID_FOR_USERCENTRICS: 'BJ59EidsWQ',
    SENTRY_ENABLED: true,
  }

  configs.local = {
    ...configs.dev,
    GTM: false,
    COOKIE_CONSENT: undefined,
    GTM_ID_FOR_USERCENTRICS: '',
    SHOW_PORTION_OPTION_NAME: false,
    SENTRY_ENABLED: false,
  }

  configs.mr = {
    ...configs.dev,
    appsync: {
      ...configs.dev.appsync,
      API: {
        GraphQL: {
          ...configs.dev.appsync.API.GraphQL,
          endpoint: graphqlEndpoint || configs.dev.appsync.API.GraphQL.endpoint,
        },
      },
    },
    SENTRY_ENABLED: true,
  }
}
const config = configs[currentEnv]

export default config
