import {useReactiveVar} from '@apollo/client'
import {
  PortionOptionSectionType,
  TableStatusType,
  TableType,
  UpdateCartAction,
} from '@marketplace/shared-lib/graphql/graphql'
import {Badge, BadgeList} from '@marketplace/shared-lib/src/UI/Badge/Badge'
import Image from '@marketplace/shared-lib/src/UI/Image/Image'
import formatPrice from '@marketplace/shared-lib/src/utils/formatPrice'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import {areGlobalTableActionsEnabled} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Text} from '@s-group/design-system-components'
import CoopMemberPrice from 'Components/Price/CoopMemberPrice'
import {PortionOptionWithDisabledValue} from 'Components/SelectionBox/SelectionBoxTypes'
import {mapOrderItemsToDataLayerItems} from 'hooks/useDataLayer'
import {usePortionRecommendations} from 'hooks/useRecommendations'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEvent from 'hooks/useTriggerEvent'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import useUpdateCartMutation from 'hooks/useUpdateCartMutation'
import {useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {previewMode} from 'state/reactiveVariables'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {EventItemContext} from 'types/analyticsTypesNew'
import {PortionSelectedIds} from 'types/productCardTypes'
import {OrderAndCartFields} from 'types/tableTypes'
import {mapPortionToEventItem} from 'utils/analytics'
import {resolveIsUrlValid} from 'utils/images'
import {resolveSelectionInstructions} from 'utils/menuUtils'
import {getLowestPortionPrice, getPortionsTotalPrices} from 'utils/portionPriceUtils'
import SelectionBoxGroup from '../SelectionBox/SelectionBoxGroup'
import ProductRecommendations from './ProductRecommendations'
import {
  ActionButton,
  AdditionalInfoInput,
  AdditionalInfoLabel,
  BottomActionWrapperFixed,
  BottomNotification,
  BottomWrapperFixed,
  ButtonWrapper,
  OptionsWrapper,
  PriceLabel,
  PriceValue,
  ProductBasicInfo,
  ProductDetailsContainer,
  ProductDetailsDescription,
  ProductDetailsImageWrapper,
  ProductDetailsTitle,
  ProductDetailsWrapper,
  SelectionIstructions,
  SizeOptionsTitle,
  ToastNotification,
} from './ProductStyles'
import {
  PortionWithMenuID,
  findPortionFromMenus,
  getButtonType,
  getPortionMenuSection,
  getPortionSelections,
  getPortionsDefaultSelections,
  getSelectedIds,
  getSelectedPortionOptions,
} from './productCardUtils'

type ProducDetailsProps = {
  portionID: string
  menuID: string
  editMode?: boolean
  cartItemID: string
  /** Called immediately after user has clicked the "Add to order" button */
  addToCartCallback?: () => void
}

/**
 * Detailed product info. Shown in a modal when user clicks a ProductCard in a menu.
 */

const ProductDetails = ({portionID, menuID, cartItemID, editMode, addToCartCallback}: ProducDetailsProps) => {
  const {restaurantData, restaurantFeatures, tableData, cartData} = useRestaurantContext()
  const isPreviewMode = useReactiveVar(previewMode)
  const {triggerEvent} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()
  const defaultPortion = useMemo(
    () => findPortionFromMenus(portionID, restaurantData?.getRestaurant.menus, menuID),
    [portionID, restaurantData?.getRestaurant.menus, menuID]
  )

  const tableActionsEnabled = areGlobalTableActionsEnabled({
    tableActions: restaurantFeatures?.tableActions,
    tableActionsAutomatic: restaurantFeatures?.tableActionsAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
    lastCallBuffer: restaurantFeatures?.lastCallBuffer,
  })

  const tableType = tableData?.getTable.tableType
  const roomServiceDisabled = tableType === TableType.ROOM && !restaurantFeatures?.roomService.enabled

  const orderFeaturesOn =
    tableData?.getTable.state.quickOrder !== false &&
    (restaurantFeatures?.quickOrder || tableType === TableType.ROOM) &&
    tableActionsEnabled &&
    tableData?.getTable.state.status !== TableStatusType.DISABLED &&
    !roomServiceDisabled

  const isOutOfStock = restaurantFeatures?.disabledPortions?.some((disabledPortion) => disabledPortion.id === portionID)

  const {portionRecommendationsHeader, recommendedPortions} = usePortionRecommendations(portionID)
  const allPortions = [defaultPortion, ...recommendedPortions].filter((portion): portion is PortionWithMenuID => {
    return !!portion
  })

  const {t, i18n} = useTranslation('order')
  const locale = i18n.language
  const {cartModify, loading: cartLoading} = useUpdateCartMutation()

  const [additionalInformationText, setAdditionalInformationText] = useState<string>('')
  const [portionsSelections, setPortionsSelections] = useState<PortionSelectedIds[]>([])
  const [selectedPortions, setSelectedPortions] = useState<PortionWithMenuID[]>([])
  const [isValidImageUrl, setIsValidImageUrl] = useState<boolean>(false)

  const cartItem = cartData?.getCart.items.find((item) => item.cartItemID === cartItemID)
  const cartSelectedPortionOptions = getSelectedIds(cartItem?.portion.portionOptionSections)
  const cartAdditionalInformation = cartItem?.additionalInformation || ''

  // Set initial portionsSelections & selectedPortions when defaultPortion is defined
  useEffect(() => {
    if (!defaultPortion) return

    if (!portionsSelections.length) {
      if (cartSelectedPortionOptions) {
        const cartPortionOptionSelection = {[portionID]: cartSelectedPortionOptions}

        const existingSectionIDs = cartSelectedPortionOptions.map((portion) => portion.selectedSectionId)

        // find portionOptions without selection and add them to cartPortionOptionSelection
        allPortions
          .flatMap((portion) =>
            portion.portionOptionSections.filter((section) => !existingSectionIDs.includes(section?.id))
          )
          .map((section) => section?.id)
          .forEach((id) => cartPortionOptionSelection[portionID].push({selectedSectionId: id, selectedOptionIds: []}))

        setPortionsSelections([cartPortionOptionSelection])

        const selectedPortionOptions = getSelectedPortionOptions(defaultPortion, cartSelectedPortionOptions)
        setSelectedPortions([selectedPortionOptions])
        setAdditionalInformationText(cartAdditionalInformation)
      } else {
        const portionsDefaultSelections = getPortionsDefaultSelections(allPortions)
        setPortionsSelections(portionsDefaultSelections)

        const defaultPortionSelections = getPortionSelections(portionID, portionsDefaultSelections)
        const defaultPortionItem = getSelectedPortionOptions(defaultPortion, defaultPortionSelections)
        setSelectedPortions([defaultPortionItem])
      }
    }
  }, [
    defaultPortion,
    cartAdditionalInformation,
    portionsSelections,
    allPortions,
    portionID,
    cartSelectedPortionOptions,
  ])

  useEffect(() => {
    if (triggerEventNew && defaultPortion) {
      triggerEventNew({
        event: 'view_item',
        ecommerce: {
          value: getLowestPortionPrice(defaultPortion).lowestNormal / 100,
          currency: 'EUR',
          items: [mapPortionToEventItem(defaultPortion)],
        },
      })
    }
  }, [defaultPortion, triggerEventNew])

  useEffect(() => {
    const image = defaultPortion?.images?.[0]
    if (!image) return

    const {url} = image

    const validateImageUrl = async () => {
      const isValid = await resolveIsUrlValid(url)
      setIsValidImageUrl(isValid)
    }

    if (url) {
      validateImageUrl()
    }
  }, [defaultPortion])

  if (!defaultPortion || !portionsSelections.length || !selectedPortions.length) return null

  const {name, description, portionOptionSections, id: portionId, diet} = defaultPortion
  const {url, text} = defaultPortion?.images?.[0] || {}

  const {totalNormalPrice, totalCoopMemberPrice} = getPortionsTotalPrices(selectedPortions, portionsSelections)

  const handleOptionChangeRadio = (portion: PortionWithMenuID, optionSectionId: string, optionId: string) => {
    const {id: selectedPortionId} = portion

    const updatedPortionsSelections = portionsSelections.map((selections) => {
      if (!selections[selectedPortionId]) return selections

      const newSelection = selections[selectedPortionId].map(({selectedSectionId, selectedOptionIds}) => {
        if (optionSectionId === selectedSectionId && !selectedOptionIds?.includes(optionId)) {
          return {selectedSectionId, selectedOptionIds: [optionId]}
        }
        return {selectedSectionId, selectedOptionIds}
      })
      return {[selectedPortionId]: newSelection}
    })

    const selectedPortionSelections = getPortionSelections(selectedPortionId, updatedPortionsSelections)
    if (!selectedPortionSelections) return

    const portionItem = getSelectedPortionOptions(portion, selectedPortionSelections)
    setPortionsSelections(updatedPortionsSelections)
    setSelectedPortions((prevState) => prevState.map((item) => (item.id === selectedPortionId ? portionItem : item)))
  }

  const handleOptionChangeCheckbox = (
    portion: PortionWithMenuID,
    optionSectionId: string,
    optionId: string,
    maxCount?: number | null
  ) => {
    const {id: selectedPortionId} = portion
    const portionSelections = getPortionSelections(selectedPortionId, portionsSelections)

    if (!portionSelections?.length) return

    let isDeselect = false
    let selectedOptionCount = 0

    const newSelection = portionSelections.map(({selectedSectionId, selectedOptionIds}) => {
      if (optionSectionId === selectedSectionId) {
        if (selectedOptionIds?.length) {
          selectedOptionCount = selectedOptionIds?.length
        }

        if (selectedOptionIds?.includes(optionId)) {
          isDeselect = true

          const updatedSelectedOptionIds = selectedOptionIds.filter((id) => id !== optionId) || []
          selectedOptionCount += 1
          return {selectedSectionId: optionSectionId, selectedOptionIds: updatedSelectedOptionIds}
        }
        return {selectedSectionId, selectedOptionIds}
      }
      if (selectedSectionId && selectedOptionIds?.length) {
        return {selectedSectionId, selectedOptionIds}
      }
      return {selectedSectionId, selectedOptionIds}
    })

    if (!isDeselect && maxCount && selectedOptionCount >= maxCount) return

    const updatedPortionsSelections = portionsSelections.map((selections) => {
      if (!selections[selectedPortionId]) return selections
      if (isDeselect) return {[selectedPortionId]: newSelection}

      const increasedSelection = newSelection.filter((selection) => selection?.selectedSectionId === optionSectionId)
      const ids = increasedSelection[0].selectedOptionIds || []

      const otherSections = selections[selectedPortionId].filter(
        ({selectedSectionId}) => selectedSectionId !== optionSectionId
      )

      return {
        [selectedPortionId]: [
          ...otherSections,
          {
            selectedSectionId: optionSectionId,
            selectedOptionIds: ids?.length ? [...ids, optionId] : [optionId],
          },
        ],
      }
    })

    const selectedPortionSelections = getPortionSelections(selectedPortionId, updatedPortionsSelections)
    if (!selectedPortionSelections) return

    const portionItem = getSelectedPortionOptions(portion, selectedPortionSelections)
    setPortionsSelections(updatedPortionsSelections)
    setSelectedPortions((prevState) => prevState.map((item) => (item.id === selectedPortionId ? portionItem : item)))
  }

  const title = getLocalized(name, locale)
  const descriptionTranslated = getLocalized(description, locale)
  const altText = getLocalized(text, locale) ?? title
  const recommendationsHeader = getLocalized(portionRecommendationsHeader, locale) ?? t('menu.recommendations')

  const recommendationAnalytics: EventItemContext = {
    related_item_id: portionID,
    promotion_name: recommendationsHeader,
    creative_name: 'product_recommendations',
  }

  const handleAddToCart = async () => {
    void addToCartCallback?.()

    const items: OrderAndCartFields[] = selectedPortions.map((portion) => {
      if (portion.id === portionID) {
        const currentMenuSection = getPortionMenuSection(portionId, restaurantData?.getRestaurant.menus, menuID)
        const analytics: EventItemContext = {
          index: currentMenuSection?.portions.findIndex((menuSectionPortion) => menuSectionPortion.id === portionID),
          item_list_name: currentMenuSection?.name.fi,
        }

        return {
          portion,
          menuID: portion.menuID ?? undefined,
          additionalInformation: additionalInformationText,
          analytics,
        }
      }
      return {
        portion,
        menuID: portion.menuID ?? undefined,
        analytics: {
          index: recommendedPortions.findIndex((recommendation) => recommendation && recommendation.id === portion.id),
          ...recommendationAnalytics,
        },
      }
    })

    const hasRecommendedPortions = items.length > 1
    // Analytics fields
    const defaultItem = items.filter(({portion}) => portion.id === portionID)
    const recommendedItems = items.filter(({portion}) => portion.id !== portionID)

    cartModify({
      items,
      successCallback: () => {
        triggerEvent({
          event: AnalyticsEvents.ADD_TO_CART,
          action: AnalyticsActions.ADD_TO_CART_PORTION_DETAILS,
          items: mapOrderItemsToDataLayerItems(defaultItem),
        })
        if (hasRecommendedPortions) {
          triggerEvent({
            event: AnalyticsEvents.ADD_TO_CART,
            action: AnalyticsActions.ADD_TO_CART_RECOMMENDATIONS_PORTION_OVERLAY,
            items: mapOrderItemsToDataLayerItems(recommendedItems),
          })
        }
        if (items.length === 1) {
          toast(
            <ToastNotification
              status='success'
              variant='outlined'
              icon
              close={false}
              header={t('menu.addedToOrderSingle', {
                name: getLocalized(items[0].portion.name, locale),
              })}
            />,
            {type: 'success'}
          )
        } else {
          toast(<ToastNotification status='success' icon close={false} header={t('menu.addedToOrderMultiple')} />, {
            type: 'success',
          })
        }
      },
    })
  }

  const currentItem = selectedPortions.find((portion) => portion.id === portionID)
  const portionItemPortionOptions = getSelectedIds(currentItem?.portionOptionSections)
  const noChanges =
    (cartItem?.additionalInformation || '') === additionalInformationText &&
    JSON.stringify(cartSelectedPortionOptions) === JSON.stringify(portionItemPortionOptions)

  const handleUpdateProduct = () => {
    const items: OrderAndCartFields[] = selectedPortions.map((portion) => {
      if (portion.id === portionID) {
        const currentMenuSection = getPortionMenuSection(portionId, restaurantData?.getRestaurant.menus, menuID)
        const analytics: EventItemContext = {
          index: currentMenuSection?.portions.findIndex((menuSectionPortion) => menuSectionPortion.id === portionID),
          item_list_name: currentMenuSection?.name.fi,
        }

        return {
          cartItemID,
          portion,
          quantity: cartItem?.quantity,
          menuID: portion.menuID ?? undefined,
          additionalInformation: additionalInformationText,
          analytics,
        }
      }
      return {
        cartItemID,
        portion,
        quantity: cartItem?.quantity,
        menuID: portion.menuID ?? undefined,
        analytics: {
          index: recommendedPortions.findIndex((recommendation) => recommendation && recommendation.id === portion.id),
          ...recommendationAnalytics,
        },
      }
    })

    const hasRecommendedPortions = items.length > 1
    // Analytics fields
    const defaultItem = items.filter(({portion}) => portion.id === portionID)
    const recommendedItems = items.filter(({portion}) => portion.id !== portionID)

    cartModify({
      items,
      action: UpdateCartAction.UPDATE,
      successCallback: () => {
        triggerEvent({
          event: AnalyticsEvents.UPDATE_CART,
          action: AnalyticsActions.UPDATE_CART_PORTION_DETAILS,
          items: mapOrderItemsToDataLayerItems(defaultItem),
        })
        if (hasRecommendedPortions) {
          triggerEvent({
            event: AnalyticsEvents.UPDATE_CART,
            action: AnalyticsActions.UPDATE_CART_RECOMMENDATIONS_PORTION_OVERLAY,
            items: mapOrderItemsToDataLayerItems(recommendedItems),
          })
        }
        if (addToCartCallback) addToCartCallback()
      },
    })
  }

  const handleTextAreaChange = (commentText: string) => {
    setAdditionalInformationText(commentText)
  }

  const actions = editMode ? (
    <ButtonWrapper>
      <ActionButton
        color='primary'
        variant='outlined'
        sizing='small'
        rounding='small'
        disabled={cartLoading || !orderFeaturesOn || isPreviewMode}
        onClick={addToCartCallback}
        data-testid='cancel-update'
      >
        {t('cart.cancelUpdate')}
      </ActionButton>
      <ActionButton
        color='neutral'
        variant='filled'
        sizing='small'
        rounding='small'
        disabled={cartLoading || !orderFeaturesOn || isPreviewMode || noChanges}
        onClick={handleUpdateProduct}
        data-testid='update-order'
      >
        {t('cart.editItem')}
      </ActionButton>
    </ButtonWrapper>
  ) : (
    <ButtonWrapper>
      <ActionButton
        color='neutral'
        variant='filled'
        sizing='small'
        rounding='small'
        disabled={cartLoading || !orderFeaturesOn || isPreviewMode || isOutOfStock}
        onClick={handleAddToCart}
        data-testid='add-to-order'
        $paddingSide='1rem'
      >
        {t('menu.addToOrder')}
      </ActionButton>
    </ButtonWrapper>
  )

  const orderingOffText = tableType === TableType.PICKUP ? t('menu.takeawayOrderingOff') : t('menu.mobileOrderingOff')
  const showMBottomNotification = !orderFeaturesOn || isOutOfStock

  return (
    <ProductDetailsContainer>
      {isValidImageUrl && (
        <ProductDetailsImageWrapper>
          <Image url={url} alt={altText} imageSizeMultiplier={1.5} />
        </ProductDetailsImageWrapper>
      )}
      <ProductDetailsWrapper data-testid={`product-details-${portionID}`} aria-labelledby={portionID}>
        <ProductBasicInfo>
          <ProductDetailsTitle
            variant='display'
            sizing='xxxxsmall'
            weight='semibold'
            id={portionID}
            compensateCloseIcon={!url}
          >
            {title}
          </ProductDetailsTitle>
          {descriptionTranslated && (
            <ProductDetailsDescription variant='body' sizing='small'>
              {descriptionTranslated}
            </ProductDetailsDescription>
          )}
          {diet.length > 0 && (
            <BadgeList>
              {diet.map((dietTag) => (
                <Badge
                  variant='body'
                  sizing='xsmall'
                  transform='caption'
                  key={dietTag}
                  data-testid={`diet-tag-${dietTag}`}
                >
                  {t(`menu.dietTags.full.${dietTag}`)}
                </Badge>
              ))}
            </BadgeList>
          )}
        </ProductBasicInfo>
        {portionOptionSections.map((optionSection) => {
          if (!optionSection) return null
          const {id: optionSectionId, portionOptions, type, minCount, maxCount} = optionSection || {}
          if (type === PortionOptionSectionType.HIDDEN) return null
          // minCount and maxCount can be undefined
          const definedButtonType = getButtonType(type, minCount, maxCount)
          const selectionInstructions = resolveSelectionInstructions(
            t,
            definedButtonType,
            optionSection.portionOptions.length,
            minCount,
            maxCount
          )
          const selectedOptionIds = getPortionSelections(portionId, portionsSelections)
            ?.filter(({selectedSectionId}) => selectedSectionId === optionSectionId)
            ?.flatMap((ids) => ids.selectedOptionIds)

          const checkIsEnabled = portionOptions.map((option) => {
            const isSelected = !!selectedOptionIds?.find((id) => id === option.id)
            const maxSelections = maxCount && selectedOptionIds && selectedOptionIds.length >= maxCount

            return {...option, disabled: !isSelected && maxSelections && definedButtonType === 'checkbox'}
          })

          const optionSectionNameTranslated = getLocalized(optionSection.name, locale)
          return (
            <OptionsWrapper key={`${optionSectionId}-wrapper`}>
              {optionSectionNameTranslated && (
                <SizeOptionsTitle
                  variant='heading'
                  sizing='xxxsmall'
                  weight='medium'
                  data-testid='size-options-title'
                  key={`${optionSectionId}-section-title`}
                >
                  {optionSectionNameTranslated}
                </SizeOptionsTitle>
              )}
              {selectionInstructions && (
                <SelectionIstructions
                  variant='body'
                  sizing='small'
                  key={`option-intructions-${portionID}`}
                  data-testid={`option-intructions-${portionID}`}
                >
                  {selectionInstructions}
                </SelectionIstructions>
              )}
              <SelectionBoxGroup
                isSize={type === PortionOptionSectionType.SIZE}
                key={optionSectionId}
                portionId={portionID}
                buttonType={definedButtonType}
                options={checkIsEnabled as PortionOptionWithDisabledValue[]}
                optionSectionId={optionSectionId}
                selectedIds={selectedOptionIds}
                onChange={({target: {value: optionId}}) => {
                  if (definedButtonType === 'checkbox') {
                    handleOptionChangeCheckbox(defaultPortion, optionSectionId, optionId, maxCount)
                  } else {
                    handleOptionChangeRadio(defaultPortion, optionSectionId, optionId)
                  }
                }}
              />
            </OptionsWrapper>
          )
        })}
        <OptionsWrapper>
          <AdditionalInfoLabel id='textAreaLabel' sizing='small' htmlFor='additional-comment-input'>
            {t('menu.additionalCommentLabel')}
          </AdditionalInfoLabel>
          <AdditionalInfoInput
            id='additional-comment-input'
            sizing='xsmall'
            rounding='small'
            data-testid='additional-comment-input'
            value={additionalInformationText}
            maxLength={160}
            rows={3}
            onChange={(e) => handleTextAreaChange(e.target.value)}
          />
        </OptionsWrapper>
        {!editMode && (
          <ProductRecommendations
            recommendationsHeader={recommendationsHeader}
            recommendations={recommendedPortions}
            selectedPortions={selectedPortions}
            setSelectedPortions={setSelectedPortions}
            portionsSelections={portionsSelections}
            handleOptionChangeRadio={handleOptionChangeRadio}
            handleOptionChangeCheckbox={handleOptionChangeCheckbox}
            analytics={recommendationAnalytics}
          />
        )}
      </ProductDetailsWrapper>
      <BottomWrapperFixed>
        {showMBottomNotification && (
          <BottomNotification
            variant='body'
            sizing='small'
            weight='medium'
            centered={isOutOfStock}
            data-testid={`bottom-notification-${isOutOfStock ? 'portion-out-of-stock' : 'ordering-disabled'}`}
          >
            {isOutOfStock ? t('menu.portionOutOfStock') : orderingOffText}
          </BottomNotification>
        )}
        <BottomActionWrapperFixed>
          <div>
            <PriceLabel variant='body' sizing='xsmall' transform='caption'>
              {t('menu.price')}
            </PriceLabel>
            <PriceValue data-testid={`${portionID}-price`} marginTop='0'>
              <Text variant='body' sizing='medium' weight={!totalCoopMemberPrice ? 'bold' : 'regular'}>
                {formatPrice({price: totalNormalPrice})}
              </Text>
              <CoopMemberPrice
                logoSize='1.25rem'
                coopMemberPrice={totalCoopMemberPrice}
                testId='coop-member-price'
                textSize='medium'
              />
            </PriceValue>
          </div>
          {actions}
        </BottomActionWrapperFixed>
      </BottomWrapperFixed>
    </ProductDetailsContainer>
  )
}

export default ProductDetails
