/**
 * Environment name. This is set by the CI environment.
 *
 * Default 'local' if not set.
 */
export const currentEnv: string = import.meta.env.VITE_APP_STAGE ?? 'local'

export const isProduction = currentEnv === 'prod'
export const isQa = currentEnv === 'qa'
export const isDevelopment = ['dev', 'mr'].includes(currentEnv)

export const isLocalhost = (): boolean => {
  const {hostname} = window.location
  return hostname === 'localhost' || hostname === '127.0.0.1'
}

/**
 * GraphQL endpoint URL. This is set by the CI environment.
 */
export const graphqlEndpoint = import.meta.env.VITE_APP_GQL_ENDPOINT
