import {setContext} from '@apollo/client/link/context'
import Cookies from 'js-cookie'
import {ApolloLink, Observable} from '@apollo/client'
import {cookieRaflaamoSessionToken, cookieSessionTokenExpirationTime} from 'utils/constants'
import {defineRaflaamoApiUrl} from 'hooks/useSIDLogin'

let isRefreshing = false // Indicates if a refresh is in progress
let pendingRequests: Array<() => void> = [] // Queue for requests waiting for a token refresh
const refreshMinutesBeforeExpired = 5 * 60 * 1000 // 5 minutes

/**
 * Should validate the cookie and return true session token seems to be expired or about to expire
 * Never validates to true if cookie is false
 * @returns boolean
 */
const isExpiring = () => {
  const expirationTimeInSeconds = Cookies.get(cookieSessionTokenExpirationTime)
  const expirationTimeInMilliSeconds = expirationTimeInSeconds ? parseInt(expirationTimeInSeconds, 10) * 1000 : false
  const refreshBefore = Date.now() + refreshMinutesBeforeExpired
  if (!expirationTimeInMilliSeconds) return false
  return expirationTimeInMilliSeconds < refreshBefore
}

const addPendingRequest = (callback: () => void) => {
  pendingRequests.push(callback)
}

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback())
  pendingRequests = []
}

const refreshSession = async () => {
  const refreshUrl = `${defineRaflaamoApiUrl(origin)}/api/auth/refresh`
  try {
    const response = await fetch(refreshUrl, {
      credentials: 'include',
    })
    return response.ok
  } catch {
    return false
  }
}

export const userApiAuthLink = setContext((_, {headers}) => {
  const sessionToken = Cookies.get(cookieRaflaamoSessionToken)
  return {
    headers: {
      ...headers,
      ...(sessionToken ? {'X-Session-Token': sessionToken} : {}),
    },
  }
})

/**
 * Middleware link to handle token refresh.
 * If refreshing is needed, postpone other promises to resolve after refresh is resolved
 * */
export const userApiRefreshSessionLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    const handleRequest = async () => {
      try {
        // if refreshing is pending, postpone the request until refresh is resolved
        if (isRefreshing) {
          // Wait until the token refresh is complete
          await new Promise<void>((resolve) => {
            addPendingRequest(resolve)
          })
        } // Try to refresh session token if it seems to expire soon or is already expired
        else if (isExpiring()) {
          isRefreshing = true
          const refreshSuccess = await refreshSession()
          // if we are not able to refresh tokens, clear also customeraccount cache and trigger refresh
          if (!refreshSuccess) {
            const context = operation.getContext()
            context.cache.evict({id: 'ROOT_QUERY', fieldName: 'customerAccount', broadcast: true})
            context.cache.gc()
          }
          // Resolve pending requests after refresh
          resolvePendingRequests()
        }

        // Proceed with the original request
        forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      } catch (error) {
        // try to resolve pending requests
        resolvePendingRequests()
        throw error // Forward the error to subscribers
      } finally {
        isRefreshing = false
      }
    }
    void handleRequest()
  })
})
