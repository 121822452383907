import {useReactiveVar} from '@apollo/client'
import {
  CommonError,
  PortionOptionInput,
  UpdateCartAction,
  UpdateCartMutationVariables,
  useUpdateCartMutation as useUpdateCartMutationHook,
} from '@marketplace/shared-lib/graphql/graphql'
import {hasGraphQLErrorCode} from '@marketplace/shared-lib/src/utils/error'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {cartID} from 'state/reactiveVariables'
import {EventItem} from 'types/analyticsTypesNew'
import {OrderAndCartFields} from 'types/tableTypes'
import {getPortionValue, mapEventItemContextToCartItemAnalytics, mapPortionToEventItem} from 'utils/analytics'
import {ToastNotification} from 'Components/Product/ProductStyles'
import useTriggerEventNew from './useTriggerEventNew'

const createPortionOptionInput = (portion: OrderAndCartFields['portion']): PortionOptionInput[] | null => {
  if (!portion.portionOptionSections.length) return null
  const portionOptionInput = portion.portionOptionSections
    .flatMap((portionOptionSection) =>
      portionOptionSection?.portionOptions.map((portionOption): PortionOptionInput => {
        return {
          optionSectionID: portionOptionSection?.id || '',
          optionID: portionOption.id,
        }
      })
    )
    .filter((optionInputItem) => optionInputItem) as PortionOptionInput[]
  return portionOptionInput
}

type CartModifyProps = {
  items: OrderAndCartFields[]
  successCallback?: () => void
  action?: UpdateCartAction
}

const useUpdateCartMutation = () => {
  const currentCartID = useReactiveVar(cartID)
  const {t} = useTranslation('order')
  const [setCart, {loading}] = useUpdateCartMutationHook()
  const triggerEventNew = useTriggerEventNew()

  const cartModify = (cartModifiers: CartModifyProps) => {
    const {items, action = UpdateCartAction.ADD, successCallback} = cartModifiers

    if (!currentCartID || !items) {
      return undefined
    }

    const cartItems = items.map(({cartItemID, portion, menuID, additionalInformation, analytics, quantity}) => {
      const portionOptions = createPortionOptionInput(portion)
      return {
        cartItemID,
        portionID: portion.id,
        menuID,
        portionOptions,
        quantity: quantity ?? 1,
        additionalInformation: !additionalInformation?.trim() ? undefined : additionalInformation,
        portionName: {fi: portion.name.fi, en: portion.name.en, sv: portion.name.sv},
        ...(analytics && {analytics: mapEventItemContextToCartItemAnalytics(analytics)}),
      }
    })

    const cartUpdateParameters: UpdateCartMutationVariables = {
      input: {
        id: currentCartID,
        action,
        items: cartItems,
      },
    }

    return void setCart({
      variables: cartUpdateParameters,
      onCompleted: () => {
        items.forEach((item) => {
          const eventItem: EventItem = {
            ...mapPortionToEventItem(item.portion),
            ...item.analytics,
          }
          if (action === UpdateCartAction.ADD) {
            triggerEventNew?.({
              event: 'add_to_cart',
              ecommerce: {
                value: getPortionValue(item.portion),
                currency: 'EUR',
                items: [eventItem],
              },
            })
          }
          if (action === UpdateCartAction.UPDATE) {
            triggerEventNew?.({
              event: 'add_to_cart',
              ecommerce: {
                value: getPortionValue(item.portion),
                currency: 'EUR',
                items: [eventItem],
              },
            })
          }
          if (action === UpdateCartAction.SUBTRACT) {
            triggerEventNew?.({
              event: 'remove_from_cart',
              ecommerce: {
                value: getPortionValue(item.portion),
                currency: 'EUR',
                items: [eventItem],
              },
            })
          }
        })

        if (successCallback) {
          successCallback()
        }
      },
      onError: (error) => {
        const isInvalidArgument = hasGraphQLErrorCode(error, CommonError.INVALID_ARGUMENT)
        switch (action) {
          case UpdateCartAction.ADD:
            toast(
              <ToastNotification
                status='error'
                variant='outlined'
                icon
                close={false}
                header={isInvalidArgument ? t('summary.addToCartErrorInvalidInput') : t('summary.addToCartError')}
              />,
              {
                type: 'error',
              }
            )
            break
          case UpdateCartAction.SUBTRACT:
            toast(
              <ToastNotification
                status='error'
                variant='outlined'
                icon
                close={false}
                header={t('summary.removeFromCartError')}
              />,
              {
                type: 'error',
              }
            )
            break
          default:
            toast(
              <ToastNotification
                status='error'
                variant='outlined'
                icon
                close={false}
                header={isInvalidArgument ? t('summary.updateCartErrorInvalidInput') : t('summary.updateCartError')}
              />,
              {
                type: 'error',
              }
            )
            break
        }
      },
    })
  }

  return {cartModify, loading}
}

export default useUpdateCartMutation
