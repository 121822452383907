import {large, medium, small, xmedium} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Button} from '@s-group/design-system-components'
import styled, {css} from 'styled-components'

interface StyledButtonProps {
  backGroundColor?: string
  borderColor?: string
  color?: string
  roundedBorders?: boolean
  contentWidth?: boolean
}

export const buttonBaseCss = css`
  box-sizing: border-box;
  display: block;
  font-family: acuminProCond, acuminPro, 'Helvetica Neue', sans-serif;
  font-size: ${medium};
  font-weight: 700;
  padding: ${small} ${xmedium};
  text-decoration: none;
  text-transform: uppercase;
  ${({contentWidth}: StyledButtonProps) =>
    !contentWidth &&
    css`
      margin: 0 auto;
      width: 100%;
    `}
  ${({contentWidth}: StyledButtonProps) =>
    contentWidth &&
    css`
      margin: 0;
      padding-left: ${large};
      padding-right: ${large};
    `}
  letter-spacing: 0.08em;
  min-width: 6rem;
`

export const ActionButton = styled(Button)`
  width: 100%;
  margin: 0;
`
