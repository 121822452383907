import {colors} from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import useCreateOrderMutation from 'hooks/useCreateOrderMutation'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import useCustomerDetails from 'hooks/useCustomerDetails'
import {validateText} from 'utils/order'
import useCartUtils from 'hooks/useCartUtils'
import CartRecommendations from './CartRecommendations'
import SummaryItems from './SummaryItems'
import {InputField, InputFieldHelperText, InputFieldLabel, SummaryFormRow, SummaryWrapper} from './SummaryStyles'
import SummaryOrderSubmission from './SummaryOrderSubmission'
import SummaryHeader from './SummaryHeader'
import SummaryTotal from './SummaryTotal'

/**
 * Summary/cart for regular restaurant table service.
 *
 * See also:
 * - SummaryRoomService.tsx
 * - SummaryTakeaway.tsx
 */
const SummaryDefault = () => {
  const {t} = useTranslation('order')
  const {tableData, restaurantData} = useRestaurantContext()
  const {token, restaurantID, tableType, tableID} = tableData?.getTable || {}
  const restaurantName = restaurantData?.getRestaurant.name.fi
  const {cartItems, totalPrice, totalQuantity, isFoodOrderBlockedDueToKitchenClosed} = useCartUtils()

  const setOrderProps = {
    restaurantID,
    token,
    restaurantName,
    tableID,
    tableType,
  }

  const {submitOrder, loading: submitOrderLoading} = useCreateOrderMutation(setOrderProps)
  const navigate = useNavigate()

  const customerDetails = useCustomerDetails()

  const {firstName: initialNickName} = customerDetails ?? {}

  const [navigateToMenu, setNavigateToMenu] = useState(false)
  // use previous customer details as default values
  const [nickName, setNickName] = useState(initialNickName)
  const [nameValid, setNameValid] = useState(false)
  const [nameVisited, setNameVisited] = useState(false)

  const requiresNickName = restaurantID === '152'
  const minNameLength = 1
  const submitIsDisabled =
    totalQuantity === 0 || submitOrderLoading || !nameValid || isFoodOrderBlockedDueToKitchenClosed

  useEffect(() => {
    setNameValid(!requiresNickName || validateText(minNameLength, nickName))
  }, [requiresNickName, minNameLength, nickName])

  const handleSubmitOrder = () => {
    submitOrder({
      nickName,
    })
  }

  useEffect(() => {
    if (navigateToMenu) {
      navigate(NavigationRoute.Menu)
    }

    return () => {
      setNavigateToMenu(false)
    }
  }, [navigateToMenu, navigate])

  return (
    <SummaryWrapper>
      <SummaryHeader tableType={tableType} />
      <SummaryItems cartItems={cartItems} />
      <SummaryTotal totalPrice={totalPrice} />
      <CartRecommendations />
      {!initialNickName && (
        <SummaryFormRow>
          <InputFieldLabel sizing='small' htmlFor='nickNameContainer'>
            {t('summary.nickNameLabel')}
          </InputFieldLabel>
          <InputField
            sizing='small'
            id='nickNameContainer'
            onChange={(e) => setNickName(e.target.value)}
            data-testid='nick-name-input'
            onBlur={() => setNameVisited(true)}
            error={nameVisited && !nameValid}
            autoFocus
          />
          {nameVisited && !nameValid ? (
            <InputFieldHelperText
              data-testid='nick-name-input-error'
              sizing='small'
              color={colors.SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY}
            >
              {t('summary.nameError', {length: minNameLength})}
            </InputFieldHelperText>
          ) : (
            <InputFieldHelperText sizing='small'>{t('summary.nickNameHelpText')}</InputFieldHelperText>
          )}
        </SummaryFormRow>
      )}
      <SummaryOrderSubmission handleSubmitOrder={handleSubmitOrder} submitIsDisabled={submitIsDisabled} />
    </SummaryWrapper>
  )
}

export default SummaryDefault
