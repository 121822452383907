import {useReactiveVar} from '@apollo/client'
import {TableType, type PortionFieldsFragment} from '@marketplace/shared-lib/graphql/graphql'
import {getLocalized} from '@marketplace/shared-lib/src/utils/localizeString'
import Divider from 'Components/Divider/Divider'
import {MainWrapper} from 'Components/Layout'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import ProductCard from 'Components/Product/ProductCard'
import useFilteredMenus from 'hooks/useFilteredMenus'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEventNew from 'hooks/useTriggerEventNew'
import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useSearchParams} from 'react-router'
import {customerFullAge, menuID, previewMode} from 'state/reactiveVariables'
import {MainContent} from 'styles/GeneralStyles'
import {color} from 'theme'
import type {EventItemContext} from 'types/analyticsTypesNew'
import {mapPortionToEventItem} from 'utils/analytics'
import {useToken} from 'utils/cookies'
import {generateSectionInternalName, getMenusByID} from 'utils/menuUtils'
import OrderCategoryNavigation from './OrderCategoryNavigation'
import {
  CategoryTitle,
  Description,
  DietTagsDefinitionsContainer,
  MenuCategoriesWrapper,
  MenuHeader,
  MenuHeaderWrapper,
  SectionContainerElement,
  SectionDescription,
  SectionTitle,
  Title,
} from './OrderStyles'

const Menu = () => {
  const token = useToken()
  const navigate = useNavigate()
  const {
    t,
    i18n: {language},
  } = useTranslation('order')
  const {restaurantData, restaurantFeatures, restaurantFeaturesLoading, tableData} = useRestaurantContext()
  const [, setSearchParams] = useSearchParams()
  const [activeSection, setActiveSection] = useState(generateSectionInternalName({menuIndex: 0, menuSectionIndex: 0}))
  const isPreviewModeOn = useReactiveVar(previewMode)
  const currentCustomerFullAge = useReactiveVar(customerFullAge)
  const currentMenuID = useReactiveVar(menuID)
  const triggerEventNew = useTriggerEventNew()
  const menus = useFilteredMenus()
  const menuByID = getMenusByID(restaurantData?.getRestaurant.menus, currentMenuID)
  const selectedMenu = isPreviewModeOn ? menuByID : menus
  // Filter out menus with 0 portions
  const visibleMenus = selectedMenu?.filter((menu) => menu.menuSections.length !== 0)
  const menuSectionCount = visibleMenus?.flatMap((menu) => menu.menuSections).length || 0

  const tableType = tableData?.getTable.tableType

  useEffect(() => {
    // allow empty token when preview mode is on
    if ((!token || token === '') && !isPreviewModeOn) {
      navigate('/')
    }
  }, [token, navigate, isPreviewModeOn])

  useEffect(() => {
    if (triggerEventNew && visibleMenus && currentCustomerFullAge !== undefined) {
      triggerEventNew({
        event: 'view_item_list',
        ecommerce: {
          items: visibleMenus
            .flatMap((menu) => menu.menuSections)
            .flatMap((menuSection) => menuSection.portions)
            .map((portion) => ({
              ...mapPortionToEventItem(portion),
              item_list_name: 'Menu',
            })),
        },
      })
    }
  }, [triggerEventNew, visibleMenus, currentCustomerFullAge])

  // Show loading screen while fetching restaurant features initially.
  // Don't show loading screen when refetching them if they are already loaded.
  if (restaurantFeaturesLoading && !restaurantFeatures) {
    return (
      <MainWrapper>
        <MainContent>
          <LoadingScreen />
        </MainContent>
      </MainWrapper>
    )
  }

  if (!visibleMenus || (!restaurantFeaturesLoading && visibleMenus?.length === 0))
    return <Title data-testid='no-menu-items-title'>{t('menu.noItems')}</Title>

  // callback to set which menu is currently active
  const handleSetActive = (sectionName: string) => {
    setActiveSection(sectionName)
  }

  const handleClick = (portion: PortionFieldsFragment, id: string, eventItemContext: EventItemContext) => {
    triggerEventNew?.({
      event: 'select_item',
      ecommerce: {
        items: [
          {
            ...mapPortionToEventItem(portion),
            ...eventItemContext,
          },
        ],
      },
    })
    // This will open portion detail modal
    const params = new URLSearchParams([
      ['portion', portion.id],
      ['menu', id],
    ])
    setSearchParams(params, {replace: true})
  }

  const menuPageDescription = tableType === TableType.PICKUP ? t('menu.descriptionTakeaway') : t('menu.description')

  return (
    <>
      <MenuHeaderWrapper>
        <MenuHeader>
          <Title variant='display' sizing='xxxxsmall' weight='bold' transform='uppercase'>
            {t('menu.title')}
          </Title>
          <Description variant='body' sizing='small' data-testid='order-menu-description'>
            {menuPageDescription}
          </Description>
        </MenuHeader>
        {menuSectionCount > 1 && (
          <MenuCategoriesWrapper>
            <CategoryTitle variant='heading' sizing='xxsmall' weight='bold' data-testid='menu-categories'>
              {t('menu.categories')}
            </CategoryTitle>
            <OrderCategoryNavigation
              menus={visibleMenus}
              activeSection={activeSection}
              handleSetActive={handleSetActive}
              language={language}
            />
          </MenuCategoriesWrapper>
        )}
      </MenuHeaderWrapper>
      {visibleMenus.map(({id, menuSections}, menuIndex: number) =>
        menuSections.map(({portions, name: menuSectionName, description}, menuSectionIndex: number) => {
          const menuSectionDescription = getLocalized(description, language)
          return (
            <SectionContainerElement
              name={generateSectionInternalName({menuIndex, menuSectionIndex})}
              key={`section-${menuIndex}-container-${menuSectionIndex}`}
              as='section'
              data-testid={`section-${menuIndex}-container-${menuSectionIndex}`}
            >
              <SectionTitle
                variant='display'
                sizing='xxxxsmall'
                weight='bold'
                data-testid={`section-${menuIndex}-title-${menuSectionIndex}`}
              >
                {getLocalized(menuSectionName, language)}
              </SectionTitle>
              {menuSectionDescription && (
                <SectionDescription variant='body' sizing='small'>
                  {menuSectionDescription}
                </SectionDescription>
              )}
              {portions.map((portion, index) => (
                <ProductCard
                  key={portion.id}
                  isLoading={false}
                  portion={portion}
                  onClick={() => handleClick(portion, id, {index, item_list_name: menuSectionName.fi})}
                  testId={portion.id}
                />
              ))}
            </SectionContainerElement>
          )
        })
      )}
      <Divider color={color.border.mediumSecondary} />
      <DietTagsDefinitionsContainer variant='body' sizing='small' data-testid='diet-tags-definitions-container'>
        {t('menu.dietTags.definitions')}
        <br />
        <br />
        {t('menu.ehecText')}
      </DietTagsDefinitionsContainer>
    </>
  )
}

export default Menu
