import {Button, Text} from '@s-group/design-system-components'
import styled from 'styled-components'

const ProductRecommendationCardWrapper = styled.article`
  background-color: ${({theme}) => theme.color.white};
  padding: ${({theme}) => theme.spacing.xsmall};
  display: flex;
  gap: ${({theme}) => theme.spacing.xxsmall};
`

const ProductRecommendationInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  flex: 1;
`

const ProductRecommendationBottomRow = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const ProductRecommendationPictureWrapper = styled.div`
  picture {
    display: block;
    height: 64px;
    width: 64px;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      border-radius: 2px;
    }
  }
`

const ProductRecommendationTitle = styled(Text)`
  text-align: left;
  margin: 0 0 ${({theme}) => theme.spacing.xxsmall};
`

const ProductRecommendationDietTags = styled.span`
  font-size: 0.875rem;
  font-weight: initial;
`

const ProductRecommendationPrice = styled.div`
  display: flex;
  text-align: left;
  margin: 0;
`

const AddToCartButton = styled(Button)<{$paddingSide?: string}>`
  padding: ${({theme, $paddingSide}) => `${theme.spacing.xxsmall} ${$paddingSide ?? theme.spacing.xxsmall}`};
`

const WelcomeRecommendationCardWrapper = styled.article`
  background-color: ${({theme}) => theme.color.white};
  display: flex;
  flex-direction: column;
`

const WelcomeRecommendationBottomRow = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const WelcomeRecommendationDescription = styled(Text)`
  padding-bottom: ${({theme}) => theme.spacing.xxsmall};
`

export {
  AddToCartButton,
  ProductRecommendationBottomRow,
  ProductRecommendationCardWrapper,
  ProductRecommendationDietTags,
  ProductRecommendationInfoWrapper,
  ProductRecommendationPictureWrapper,
  ProductRecommendationPrice,
  ProductRecommendationTitle,
  WelcomeRecommendationBottomRow,
  WelcomeRecommendationCardWrapper,
  WelcomeRecommendationDescription,
}
