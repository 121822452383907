import {useTranslation} from 'react-i18next'
import {Button} from '@s-group/design-system-components'
import {NavigationRoute} from 'routes/routeConstants'
import {useNavigate} from 'react-router'
import {SummaryWrapper, ButtonWrapper} from './SummaryStyles'
import CartRecommendations from './CartRecommendations'
import SummaryHeader from './SummaryHeader'

const SummaryCartEmpty = () => {
  const {t} = useTranslation('order')
  const navigate = useNavigate()

  return (
    <SummaryWrapper>
      <SummaryHeader cartEmpty />
      <CartRecommendations />
      <ButtonWrapper>
        <Button
          color='neutral'
          variant='filled'
          sizing='medium'
          rounding='small'
          data-testid='button-back'
          onClick={() => navigate(NavigationRoute.Menu)}
        >
          {t('summary.back')}
        </Button>
      </ButtonWrapper>
    </SummaryWrapper>
  )
}

export default SummaryCartEmpty
