import {Button, Loader} from '@s-group/design-system-components'
import {IconUserProfile, IconUserProfileFilled} from '@s-group/design-system-icons'
import {useState} from 'react'
import {useGetCustomerAccountQuery} from '@marketplace/shared-lib/graphql/graphql'
import {SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL} from '@s-group/design-system-tokens/web/tokens/raflaamo/colors.es6'
import LoginPopUp from './LoginPopUp'
import {LoginButtonWrapper} from './CustomerAccountButtonStyles'

type Props = {
  desktop?: boolean
}

type CustomerIconProps = {
  isLoggedIn: boolean
  loading: boolean
}

const CustomerIcon = ({isLoggedIn, loading}: CustomerIconProps) => {
  if (loading) {
    return (
      <Loader
        size='small'
        type='circle'
        data-testid='customer-account-loader'
        color={SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL}
      />
    )
  }
  return isLoggedIn ? <IconUserProfileFilled /> : <IconUserProfile />
}

const CustomerAccountButton = ({desktop}: Props) => {
  const [visible, setVisible] = useState<boolean>(false)

  const {data: customerData, loading} = useGetCustomerAccountQuery({
    fetchPolicy: 'cache-first',
  })
  const customer = customerData?.getCustomerAccount.customer
  const isLoggedIn = !!customer

  const toggleVisible = () => {
    if (!loading) {
      setVisible(!visible)
    }
  }

  return (
    <LoginButtonWrapper>
      <Button
        sizing='small'
        variant='filled'
        color='neutral'
        icon={<CustomerIcon isLoggedIn={isLoggedIn} loading={loading} />}
        iconOnly={!desktop}
        onClick={toggleVisible}
        data-testid='usermenu-login-button'
      />
      <LoginPopUp customer={customer || null} visible={visible} setVisible={setVisible} />
    </LoginButtonWrapper>
  )
}

export default CustomerAccountButton
