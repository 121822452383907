export const remoteCartID = 'remoteCartID'
export const tokenCookieKey = 'tilaaRaflaamoToken'
export const feedbackScoreCookieKey = 'feedbackScoreGiven'
export const remoteOrderRoomNumber = 'remoteOrderRoomNumber'
export const remoteCustomerFullAge = 'remoteCustomerFullAge'
export const remoteRestaurantSelectionToken = 'restaurantSelectionToken'
export const remoteWelcomeRecommationsShown = 'remoteWelcomeRecommationsShown'
export const remoteOrderNickName = 'remoteOrderNickName'

// SID related cookies
export const cookieRaflaamoSessionToken = 'raflaamo_session_token'
export const cookieRaflaamoCancelUrl = 'raflaamo_cancel_url'
export const cookieSessionTokenExpirationTime = 'session_token_expiration_time'
