import config from 'config'
import Cookies, {CookieAttributes} from 'js-cookie'
import {useCookies} from 'react-cookie'
import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {isLocalhost, isProduction} from '@marketplace/shared-lib/src/utils/envUtils'
import {feedbackScoreCookieKey, tokenCookieKey} from './constants'

const cookieExpiryMs = config.COOKIE_EXPIRY_TIME_MS
const cookieExpiryMsPickup = 12 * 60 * 60 * 1000

export const useToken = (): string => {
  const [cookies] = useCookies([tokenCookieKey])
  return cookies.tilaaRaflaamoToken || ''
}

export const getHasGivenFeedbackScore = () => {
  return Cookies.get(feedbackScoreCookieKey) || ''
}

export const setHasGivenFeedbackScore = () => {
  const now = new Date()
  const expirytime = new Date()
  expirytime.setTime(now.getTime() + cookieExpiryMs)
  return Cookies.set(feedbackScoreCookieKey, '1', {
    expires: expirytime,
  })
}

export const getCookieExpiryTime = (tableType: TableType) => {
  // Default cookie expiry time 2h, use 12h for pickup tables
  const cookieExpiryTime = tableType === TableType.PICKUP ? cookieExpiryMsPickup : cookieExpiryMs
  return new Date(new Date().getTime() + cookieExpiryTime)
}

const defineCookieDomain = () => {
  if (isLocalhost()) {
    return 'localhost'
  }
  if (isProduction) {
    return '.raflaamo.fi'
  }
  return '.beta-test.raflaamo.fi'
}

export const domain = defineCookieDomain()

export const cancelLoginCookieParams: CookieAttributes = {
  sameSite: isLocalhost() ? 'lax' : 'strict',
  secure: true,
  httpOnly: false,
  domain,
}
