import {Button} from '@s-group/design-system-components'
import {useTranslation} from 'react-i18next'
import {IconNavigationArrowRight} from '@s-group/design-system-icons'
import {SendButtonWrapper} from './SummaryStyles'

interface SummaryOrderSubmissionProps {
  handleSubmitOrder: () => void
  submitIsDisabled: boolean
}

/**
 * Order submit button and possible error notification.
 */
const SummaryOrderSubmission = ({submitIsDisabled, handleSubmitOrder}: SummaryOrderSubmissionProps) => {
  const {t} = useTranslation('order')

  return (
    <SendButtonWrapper>
      <Button
        // type is important here; it should be 'button' to prevent the default behavior of form submission
        type='button'
        disabled={submitIsDisabled}
        data-testid='send-order-button'
        color='neutral'
        variant='filled'
        sizing='medium'
        rounding='small'
        onClick={handleSubmitOrder}
        icon={IconNavigationArrowRight}
        iconPos='after'
      >
        {t('summary.sendOrder')}
      </Button>
    </SendButtonWrapper>
  )
}

export default SummaryOrderSubmission
