import {PortionType, RushHourType, TableStatusType, TableType} from '@marketplace/shared-lib/graphql/graphql'
import {resolveRestaurantOpen} from '@marketplace/shared-lib/src/utils/openingTimes'
import {isRestaurantKitchenOpen} from '@marketplace/shared-lib/src/utils/restaurantUtils'
import {Text} from '@s-group/design-system-components'
import useRestaurantContext from 'hooks/useRestaurantContext'
import {useEffect, useRef} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {topNotificationsHeight} from 'state/reactiveVariables'
import {NotificationBannerContainer, NotificationBannerWrapper} from './NotificationBannerStyles'

export type NotificationType =
  | 'rushHour'
  | 'kitchenClosed'
  | 'kitchenClosedRemoveFoodItems'
  | 'roomServiceClosed'
  | 'orderFeaturesOff'

const ROOM_SERVICE_OR_TAKEAWAY_TABLE = [TableType.PICKUP, TableType.OPEN_ROOM, TableType.ROOM]

const NotificationContent = ({type}: {type: NotificationType}) => {
  const {t} = useTranslation('order')
  const {restaurantFeatures, tableData} = useRestaurantContext()

  const tableType = tableData?.getTable.tableType
  const {rushHour, roomService} = restaurantFeatures || {}

  const rushHourText =
    rushHour?.rushHourType === RushHourType.KITCHEN ? 'notification.kitchenRushHour' : 'notification.generalRushHour'

  const rushHourDelay = rushHour?.delay || 20
  /* add room service delay if room service  */
  const roomServiceDelay = rushHourDelay + (roomService?.delay ?? 0)
  const delay = tableType === TableType.ROOM || tableType === TableType.OPEN_ROOM ? roomServiceDelay : rushHourDelay

  if (type === 'orderFeaturesOff') {
    const notificationText = ROOM_SERVICE_OR_TAKEAWAY_TABLE.includes(tableType || TableType.GENERAL)
      ? t('notification.serviceNotAvailable')
      : t('notification.orderFeaturesOff')
    return (
      <Text variant='body' sizing='small' data-testid='order-features-disabled'>
        {notificationText}
      </Text>
    )
  }
  if (type === 'rushHour') {
    return (
      <span data-testid='rush-hour-enabled'>
        <Trans i18nKey={rushHourText} ns='order' values={{delay}} components={{b: <b />}} />
      </span>
    )
  }
  if (type === 'kitchenClosedRemoveFoodItems') {
    return (
      <span data-testid='kitchen-closed-remove-food-items'>
        <Trans i18nKey='notification.kitchenClosedRemoveFoodItems' ns='order' components={{b: <b />}} />
      </span>
    )
  }
  if (type === 'kitchenClosed') {
    return (
      <span data-testid='kitchen-closed'>
        <Trans i18nKey='notification.kitchenClosed' ns='order' components={{b: <b />}} />
      </span>
    )
  }
  return null
}

const NotificationBanner = () => {
  const ref = useRef<HTMLDivElement>(null)
  const {restaurantFeatures, tableData, restaurantData, cartData} = useRestaurantContext()
  const {rushHour} = restaurantFeatures || {}
  const {tableType} = tableData?.getTable || {}
  const {enabled: roomServiceEnabled} = restaurantFeatures?.roomService ?? {}

  useEffect(() => {
    if (ref.current) {
      const {offsetHeight} = ref.current
      topNotificationsHeight(offsetHeight)
    } else {
      topNotificationsHeight(0)
    }
  }, [restaurantFeatures])

  // @TODO: replace with restaurantUtils function
  const isOrderingEnabled = restaurantFeatures?.tableActionsAutomatic
    ? resolveRestaurantOpen({
        flagType: 'tableActions',
        openingTimes: restaurantData?.getRestaurant.weekOpeningTimes,
        lastCallBuffer: restaurantFeatures?.lastCallBuffer,
      })
    : restaurantFeatures?.tableActions && restaurantFeatures?.quickOrder

  const isKitchenOpen = isRestaurantKitchenOpen({
    kitchenClosed: restaurantFeatures?.kitchenClosed,
    kitchenOpenAutomatic: restaurantFeatures?.kitchenOpenAutomatic,
    weekOpeningTimes: restaurantData?.getRestaurant.weekOpeningTimes,
  })

  const hasFoodItemsInCart = cartData?.getCart?.items?.some((item) => item.portion.type === PortionType.DISH)

  const roomServiceClosed = (tableType === TableType.ROOM || tableType === TableType.OPEN_ROOM) && !roomServiceEnabled

  const kitchenClosed = !isKitchenOpen
  const isRushHour = rushHour?.enabled
  const rushHourType = rushHour?.rushHourType

  const orderFeaturesOn =
    // Restaurant is open
    isOrderingEnabled &&
    //  ...quick order is enabled or table type is room...
    (restaurantFeatures?.quickOrder || tableType === TableType.ROOM) &&
    // ..and quick order is enabled...
    tableData?.getTable.state.quickOrder !== false &&
    // ...and table state is not disabled
    tableData?.getTable.state.status !== TableStatusType.DISABLED

  const orderFeaturesOff = !orderFeaturesOn && tableType !== TableType.RESTAURANT_SELECTION

  /**
   * Show only the highest priority notification.
   *
   * With the exception when kitchen is closed and general rush hour is enabled,
   * both notifications will be shown.
   */
  let primaryNotification: NotificationType | undefined
  let secondaryNotification: NotificationType | undefined

  switch (true) {
    case roomServiceClosed:
      primaryNotification = 'orderFeaturesOff'
      break
    case orderFeaturesOff:
      if (tableType !== TableType.ROOM && tableType !== TableType.OPEN_ROOM) {
        primaryNotification = 'orderFeaturesOff'
      } else if (tableData?.getTable.state.quickOrder === false || restaurantFeatures?.tableActions === false) {
        /**
         * Show orderFeaturesOff notification only when tableActions
         * or table spaceific ordering is deactivated.
         */
        primaryNotification = 'orderFeaturesOff'
      }
      if (!roomServiceClosed && isRushHour) {
        /**
         * Show rush hour notification in room service even if service is closed.
         * Room service can be still active even when ordering is not.
         */
        primaryNotification = 'rushHour'
      }
      break
    case kitchenClosed:
      if (hasFoodItemsInCart) {
        primaryNotification = 'kitchenClosedRemoveFoodItems'
      } else {
        primaryNotification = 'kitchenClosed'
      }
      if (isRushHour && rushHourType === RushHourType.GENERAL) {
        secondaryNotification = 'rushHour'
      }
      break
    case isRushHour:
      primaryNotification = 'rushHour'
      break
    default:
      return null
  }

  return (
    <NotificationBannerContainer ref={ref}>
      {primaryNotification && (
        <NotificationBannerWrapper
          status='warning'
          variant='tonal'
          sizing='small'
          close={false}
          data-testid={`notification-banner-primary-${primaryNotification}`}
          key={primaryNotification}
        >
          <NotificationContent type={primaryNotification} />
        </NotificationBannerWrapper>
      )}
      {secondaryNotification && (
        <NotificationBannerWrapper
          status='warning'
          variant='tonal'
          sizing='small'
          close={false}
          data-testid={`notification-banner-secondary-${secondaryNotification}`}
          key={secondaryNotification}
        >
          <NotificationContent type={secondaryNotification} />
        </NotificationBannerWrapper>
      )}
    </NotificationBannerContainer>
  )
}

export default NotificationBanner
