import {useApolloClient} from '@apollo/client'
import {useState, type Dispatch, type SetStateAction} from 'react'
import {Customer, LoginType, useCustomerLoginMutation} from '@marketplace/shared-lib/graphql/graphql'
import Cookies from 'js-cookie'
import useSIDLogin from 'hooks/useSIDLogin'
import {cancelLoginCookieParams} from 'utils/cookies'
import {cookieRaflaamoCancelUrl} from 'utils/constants'
import {readLocalStorage} from '@marketplace/shared-lib/src/utils/localStorage'
import LoggedInView from './LoggedInView'
import LoginPopUpForNotLogged from './NotLoggedInView'

type LoginPopUpProps = {
  visible: boolean
  setVisible: Dispatch<SetStateAction<boolean>>
  customer: Customer | null
}

const LoginPopUp = ({visible, setVisible, customer}: LoginPopUpProps) => {
  const client = useApolloClient()
  const [login] = useCustomerLoginMutation()
  const {postLoginUrl, redirectUrl, logoutUrl} = useSIDLogin()
  const [logoutLoading, setLogoutLoading] = useState<boolean>(false)

  const initiateLogin = (loginOrRegister: LoginType) =>
    login({
      variables: {
        input: {
          postLoginUrl: btoa(postLoginUrl),
          redirectUrl: btoa(redirectUrl),
          loginType: loginOrRegister,
          loginLang: readLocalStorage('i18nextLng') ?? 'fi',
        },
      },
      onCompleted: async (data) => {
        if (data.customerLogin.loginUrl) {
          // redirect to login url and create a cookie for possible cancellation of the flow
          Cookies.set(cookieRaflaamoCancelUrl, postLoginUrl, cancelLoginCookieParams)
          window.location.href = data.customerLogin.loginUrl
        }
      },
      onError: (err) => {
        // TODO: handle login url errors
        console.error(err)
      },
    })

  const handleLogout = async () => {
    setLogoutLoading(true)
    try {
      await fetch(logoutUrl, {
        credentials: 'include',
      })
      client.cache.evict({id: 'ROOT_QUERY', fieldName: 'getCustomerAccount', broadcast: true})
      client.cache.gc()
      setVisible(false)
      // triggerLogoutEvent()
    } catch (err) {
      // TODO: handle logout url errors
      console.error(err)
    } finally {
      setLogoutLoading(false)
    }
  }

  if (!visible) {
    return null
  }
  if (customer) {
    return (
      <LoggedInView
        customer={customer}
        setVisible={setVisible}
        initiateLogout={handleLogout}
        loggingOutLoading={logoutLoading}
      />
    )
  }

  return (
    <LoginPopUpForNotLogged loading={logoutLoading} setVisible={setVisible} initiateLoginOrRegister={initiateLogin} />
  )
}

export default LoginPopUp
