import {ApolloError} from '@apollo/client'

// Errors from the backend have some extra fields that are not in the default
// types. This declaration adds those fields.
declare module 'graphql' {
  interface GraphQLFormattedError {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any
    errorType: string
    errorInfo: {
      code: string
    }
  }

  interface SourceLocation {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sourceName: any
  }
}

/**
 * Check it Apollo Error contains a specific GraphQL error code.
 *
 * @example
 * someGQLQuery()
 *   .onError((error) => {
 *     if (hasGraphQLErrorCode(error, CommonError.INVALID_ARGUMENT)) {
 *       // show error notification or something
 */
export function hasGraphQLErrorCode(error: ApolloError, code: string): boolean {
  // since we do the type-casting, let's be safe and catch any errors
  try {
    return (error.graphQLErrors ?? []).some((gqlError) => gqlError?.errorInfo?.code === code)
  } catch (e) {
    console.error('Error while checking if ApolloError has a specific GraphQL error type', e, error)
  }
  return false
}
