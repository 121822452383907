import {ApolloProvider} from '@apollo/client'
import {BrandWrapper} from '@s-group/design-system-components'
import * as MarketPlaceTheme from '@s-group/design-system-tokens/web/tokens/raflaamo/theme'
import apolloClient from 'apollo/apolloClient'
import {useUCScript} from 'hooks/useUCScript'
import RemoteRoutes from 'routes/RemoteRoutes'
import ModalProvider from 'state/ModalProvider'
import RestaurantProvider from 'state/RestaurantProvider'
import {ThemeProvider} from 'styled-components'
import GlobalStyle from 'styles/GlobalStyle'
import {remoteTheme} from 'theme'
import {useSentry} from 'utils/sentryInit'

const App = (): JSX.Element => {
  useUCScript()
  useSentry()

  return (
    <ApolloProvider client={apolloClient}>
      <BrandWrapper theme={MarketPlaceTheme}>
        <ThemeProvider theme={remoteTheme}>
          <GlobalStyle />
          <RestaurantProvider>
            <ModalProvider>
              <RemoteRoutes />
            </ModalProvider>
          </RestaurantProvider>
        </ThemeProvider>
      </BrandWrapper>
    </ApolloProvider>
  )
}

export default App
