import {StrictMode} from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router'
import {UsercentricsProvider} from '@s-group/react-usercentrics'
import ScrollToTop from 'Components/ScrollToTop'
import App from './App'
import './i18n'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <UsercentricsProvider windowEventName='ucEvent'>
        <App />
      </UsercentricsProvider>
    </BrowserRouter>
  </StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals()
