import {BrandWrapper, Button, Text} from '@s-group/design-system-components'
import * as Theme from '@s-group/design-system-tokens/web/tokens/sbrand/theme'
import type {Dispatch, SetStateAction} from 'react'
import {useTranslation} from 'react-i18next'
import {CustomerLoginMutation, LoginType} from '@marketplace/shared-lib/graphql/graphql'
import type {FetchResult} from '@apollo/client'
import {IconLogoSgroup} from '@s-group/design-system-icons'
import {LoginLinksWrapper, LogoWrapper, PopUpHeading, PopupRow, PopUpText, TextsWrapper} from './LoginPopUpStyles'
import LoginPopUpArea from './LoginPopUpArea'

type Props = {
  loading: boolean
  initiateLoginOrRegister: (loginOrRegister: LoginType) => Promise<FetchResult<CustomerLoginMutation>>
  setVisible: Dispatch<SetStateAction<boolean>>
}

const LoginPopUpForNotLogged = ({setVisible, initiateLoginOrRegister, loading}: Props) => {
  const {t} = useTranslation('login')

  return (
    <LoginPopUpArea setVisible={setVisible}>
      <PopupRow $alignCenter>
        <LogoWrapper>
          <IconLogoSgroup size='24' width='2rem' height='2rem' />
        </LogoWrapper>
        <TextsWrapper>
          <PopUpHeading tagName='h2' sizing='small' weight='bold'>
            {t('loginRaflaamo')}
          </PopUpHeading>
          <PopUpText tagName='h3' variant='heading' sizing='xxxsmall'>
            {t('whenLogin')}
          </PopUpText>
        </TextsWrapper>
        <TextsWrapper>
          <BrandWrapper theme={Theme}>
            <Button
              onClick={() => initiateLoginOrRegister(LoginType.LOGIN)}
              sizing='small'
              variant='filled'
              rounding='pill'
              aria-disabled={loading}
              disabled={loading}
              data-testid='login-popup-login-button'
            >
              <Text tagName='span' variant='body' weight='medium'>
                {t('login')}
              </Text>
            </Button>
            <LoginLinksWrapper>
              <Text tagName='span' variant='body' weight='medium'>
                {t('newCustomer')}?{' '}
              </Text>
              <Button
                data-testid='login-popup-register-button'
                variant='plain'
                compact
                aria-disabled={loading}
                disabled={loading}
                sizing='small'
                onClick={() => initiateLoginOrRegister(LoginType.REGISTER)}
                aria-label={`${t('newCustomer')} ${t('startHere')}`}
              >
                {t('startHere')}
              </Button>
            </LoginLinksWrapper>
          </BrandWrapper>
        </TextsWrapper>
      </PopupRow>
    </LoginPopUpArea>
  )
}
export default LoginPopUpForNotLogged
