import {useReactiveVar} from '@apollo/client'
import {
  CreateOrderInput,
  GetCartDocument,
  ModifyOrdersAction,
  ModifyOrdersMutationVariables,
  TableType,
  useModifyOrdersMutation,
} from '@marketplace/shared-lib/graphql/graphql'
import {useNavigate} from 'react-router'
import {NavigationRoute} from 'routes/routeConstants'
import {cartID} from 'state/reactiveVariables'
import {AnalyticsActions, AnalyticsEvents} from 'types/analyticsTypes'
import {mapCartItemAnalyticsToEventItemContext, mapPortionToEventItem} from 'utils/analytics'
import {toast} from 'react-toastify'
import {ToastNotification} from 'Components/Product/ProductStyles'
import {useTranslation} from 'react-i18next'
import {mapCartToDataLayerEvent, triggerEvent} from './useDataLayer'
import useTriggerEventNew from './useTriggerEventNew'

type SetOrderDetailsProps = {
  restaurantName?: string
  token?: string
  restaurantID?: string
  tableID?: string
  tableType?: TableType
}

type SubmitOrderOptions = Pick<
  CreateOrderInput,
  'nickName' | 'lastName' | 'phoneNumber' | 'pickupTime' | 'loyaltyProgram' | 'paymentMethod'
>

const useCreateOrderMutation = (setOrderDetails: SetOrderDetailsProps) => {
  const {t} = useTranslation('order')
  const currentCartID = useReactiveVar(cartID)
  const navigate = useNavigate()
  const [setOrder, {loading}] = useModifyOrdersMutation()
  const triggerEventNew = useTriggerEventNew()

  const submitOrder = ({
    nickName,
    lastName,
    phoneNumber,
    pickupTime,
    loyaltyProgram,
    paymentMethod,
  }: SubmitOrderOptions) => {
    const {token, restaurantID, restaurantName, tableID, tableType} = setOrderDetails || {}
    // to be able to create order, we need to know which restaurant and table and cart is used
    if (!currentCartID || !token || !restaurantID) {
      return undefined
    }

    const setOrderParameters: ModifyOrdersMutationVariables = {
      input: {
        action: ModifyOrdersAction.CREATE_ORDER,
        token,
        createOrderInput: {
          restaurantID,
          cartID: currentCartID,
          nickName,
          lastName,
          phoneNumber,
          pickupTime,
          loyaltyProgram,
          paymentMethod,
        },
      },
    }

    return void setOrder({
      variables: setOrderParameters,
      // cart will be emptied so we need to refetch it
      refetchQueries: [{query: GetCartDocument, variables: {input: {id: currentCartID, restaurantID}}}],
      awaitRefetchQueries: true,
      update(cache, {data: modifyOrdersData}) {
        cache.modify({
          id: cache.identify({__typename: 'Table', token}),
          fields: {
            orders() {
              return modifyOrdersData?.modifyOrders.orders ?? null
            },
          },
        })
      },
      onCompleted: (data) => {
        const {madeOrder} = data.modifyOrders
        if (madeOrder) {
          triggerEvent({
            event: AnalyticsEvents.MAKE_ORDER,
            action: AnalyticsActions.SEND_ORDER,
            restaurant_name: restaurantName,
            table: tableID,
            items: mapCartToDataLayerEvent(madeOrder),
            table_type: tableType,
          })
          triggerEventNew?.({
            event: 'purchase',
            ecommerce: {
              value: madeOrder.cartTotal.normal / 100,
              currency: 'EUR',
              transaction_id: madeOrder.id,
              items: madeOrder.cart.map((cartItem) => ({
                ...mapPortionToEventItem(cartItem.portion),
                ...mapCartItemAnalyticsToEventItemContext(cartItem.analytics),
                quantity: cartItem.quantity,
              })),
            },
          })

          navigate(`${NavigationRoute.OrderConfirmation}/${madeOrder?.id}`)
        }
      },
      onError: () => {
        // Apollo Client has an error handler that will log the error to Sentry,
        // so no need to log it here.
        // Let's just show a toast notification to the user.
        toast(
          <ToastNotification
            status='error'
            variant='outlined'
            icon
            close={false}
            header={t('summary.sendOrderErrorHeader')}
          >
            {t('summary.sendOrderErrorMessage')}
          </ToastNotification>,
          {type: 'success'}
        )
      },
    })
  }

  return {submitOrder, loading}
}

export default useCreateOrderMutation
