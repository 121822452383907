import {TableType} from '@marketplace/shared-lib/graphql/graphql'
import {Text} from '@s-group/design-system-components'
import Divider from 'Components/Divider/Divider'
import LoadingScreen from 'Components/LoadingScreen/LoadingScreen'
import useRestaurantContext from 'hooks/useRestaurantContext'
import useTriggerEvent from 'hooks/useTriggerEvent'
import {useTranslation} from 'react-i18next'
import {color} from 'theme'
import useTriggerEventNew from '../../hooks/useTriggerEventNew'
import AgeDialog from './AgeDialog'
import LanguageButtons from './LanguageButtons'
import {WelcomeHeader, WelcomeMain, WelcomeTextWrapper} from './WelcomeStyles'

const Welcome = () => {
  const {t} = useTranslation('welcome')
  const {tableData} = useRestaurantContext()

  const tableType = tableData?.getTable.tableType
  /**
   * Do NOT show welcome text on room service.
   * We still want to present age dialog in case there are alcohol on the menu
   */
  const showWelcomeText = tableType !== TableType.ROOM && tableType !== TableType.OPEN_ROOM

  const {loading} = useTriggerEvent()
  const triggerEventNew = useTriggerEventNew()

  if (loading || !triggerEventNew) return <LoadingScreen />

  return (
    <WelcomeMain autoFocus data-testid='main' aria-modal role='dialog'>
      <LanguageButtons aria-label='language-selectors' />
      {showWelcomeText && (
        <>
          <WelcomeTextWrapper
            data-testid='main'
            aria-labelledby='welcome-dialog-header'
            aria-describedby='welcome-dialog-paragraph'
          >
            <WelcomeHeader
              tagName='h3'
              variant='display'
              sizing='xxxxsmall'
              weight='bold'
              transform='uppercase'
              data-testid='welcome-dialog-header'
              id='welcome-dialog-header'
            >
              {t('header')}
            </WelcomeHeader>
            <Text sizing='small' data-testid='welcome-dialog-paragraph' id='welcome-dialog-paragraph'>
              {t('welcomeParagraph')}
            </Text>
          </WelcomeTextWrapper>
          <Divider color={color.border.mediumSecondary} />
        </>
      )}
      <AgeDialog paddingTop={!showWelcomeText} />
    </WelcomeMain>
  )
}

export default Welcome
