import {BrandWrapper, LinkButton, Link, Notification} from '@s-group/design-system-components'
import {IconCommunicationMobile, IconNavigationChevronRight, IconStatusErrorFilled} from '@s-group/design-system-icons'
import {
  SDS_BRAND_COLOR_TEXT_ERROR,
  SDS_BRAND_COLOR_TEXT_SUCCESS,
} from '@s-group/design-system-tokens/web/tokens/raflaamo/colors.es6'
import * as SBrandTheme from '@s-group/design-system-tokens/web/tokens/sbrand/theme'
import {useTranslation} from 'react-i18next'
import {DoubleIconWrapper, NotificationWrapper, PopUpHeading, PopupRow, PopUpText} from '../Login/LoginPopUpStyles'
import {getLinkToUserProfile, getLinkToJoinLoyalty} from './sidLinkUtils'

const TrailerComponent = () => {
  const {t, i18n} = useTranslation('login')
  const confirmPhoneNumberLink = getLinkToUserProfile(i18n.language)
  const joinLoyaltyLink = getLinkToJoinLoyalty(i18n.language)
  return (
    <NotificationWrapper>
      <PopUpText sizing='small' variant='body' weight='regular' data-testid='confirm-phone-number-description'>
        {t('confirmPhoneNumberDescription')}
      </PopUpText>
      <BrandWrapper theme={SBrandTheme}>
        <LinkButton
          color='primary'
          data-testid='confirm-phone-number-action'
          variant='filled'
          rounding='pill'
          sizing='xsmall'
          href={confirmPhoneNumberLink}
        >
          {t('confirmPhoneNumberAction')}
        </LinkButton>
      </BrandWrapper>

      <Link href={joinLoyaltyLink} color='neutral' standalone icon={<IconNavigationChevronRight size='16' />}>
        {t('joinSLoyalty')}
      </Link>
    </NotificationWrapper>
  )
}

const ConfirmPhoneNumber = () => {
  const {t} = useTranslation('login')
  return (
    <PopupRow $noPadding>
      <Notification
        alignment='left'
        contentProps='100%'
        header={
          <PopUpHeading
            sizing='small'
            variant='heading'
            weight='bold'
            tagName='h3'
            data-testid='confirm-phone-number-header'
          >
            {t('confirmPhoneNumberHeader')}
          </PopUpHeading>
        }
        icon={
          <DoubleIconWrapper>
            <IconCommunicationMobile color={SDS_BRAND_COLOR_TEXT_SUCCESS} size='24' />
            <IconStatusErrorFilled color={SDS_BRAND_COLOR_TEXT_ERROR} />
          </DoubleIconWrapper>
        }
        orientation='vertical'
        status='success'
        sizing='small'
        close={false}
        trailer={<TrailerComponent />}
      />
    </PopupRow>
  )
}

export default ConfirmPhoneNumber
