import {xsmall} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Button, Label, Notification, Text, TextArea} from '@s-group/design-system-components'
import {IconControlPlus} from '@s-group/design-system-icons'
import styled, {css, keyframes} from 'styled-components'
import {color} from 'theme'

const ProductContainerButton = styled(Button)`
  display: block;
  padding: ${({theme}) => theme.spacingCustom.medium};
  border-top: 1px solid ${color.border.mediumSecondary};
`

const ProductDetailsContainer = styled.article`
  display: block;
  height: 100%;
  overflow-x: hidden;
`

const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing.small};
  position: relative;
  height: 100%;
  padding: ${({theme}) => theme.spacingCustom.medium};

  /* BottomActionWrapperFixed height */
  margin-bottom: 88px;

  @media (width <= 280px) {
    margin-bottom: 100px;
  }
`

const ProductDetailsImageWrapper = styled.div`
  margin: ${({theme}) => `-${theme.spacingCustom.medium} -${theme.spacingCustom.medium} ${theme.spacing.xsmall}`};
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;

  picture {
    display: flex;
  }

  img {
    max-width: 100%;
    max-height: 400px;
    text-align: center;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: ${({theme}) => theme.spacing.xxsmall};
`

const ActionButton = styled(Button)<{$paddingSide?: string}>`
  padding: ${({theme, $paddingSide}) => `${theme.spacing.xxsmall} ${$paddingSide ?? theme.spacing.xxsmall}`};
`

const ProductSummaryContainer = styled.div`
  /* Intentionally left blank */
`

const ProductTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const ProductCardTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`

const ProductTitle = styled(Text)<{$textColor?: string}>`
  display: block;
  color: ${({$textColor, theme}) => $textColor ?? theme.color.text.strongerGrey};
  text-align: left;
  align-items: center;
  gap: ${({theme}) => theme.spacing.xxxsmall};
`

const IconAdd = styled(IconControlPlus)`
  background-color: ${({theme}) => theme.color.black};
  height: ${({theme}) => theme.spacing.medium};
  padding: 0 ${({theme}) => theme.spacing.xxxxsmall};
  margin-right: -${({theme}) => theme.spacing.xsmall};
  border-radius: 50%;
  align-items: center;
`

const EditButton = styled(Button)`
  margin-top: -${({theme}) => theme.spacing.xxxsmall};
  margin-bottom: -${({theme}) => theme.spacing.xxsmall};
`

const DietTagsAbbreviations = styled(Text)<{paddingLeft?: string | number}>`
  /* keep the diet tags on one line */
  padding-left: ${({theme, paddingLeft}) => paddingLeft ?? theme.spacing.xxxxsmall};
  white-space: nowrap;
`

const ProductDetails = styled(Text)`
  display: flex;
  text-align: left;
`

const ProductBasicInfo = styled.div`
  display: grid;
  gap: ${({theme}) => theme.spacing.xsmall};
`

/**
 * @param compensateCloseIcon - if true, the title will have a margin-right to
 * compensate for the close icon of the modal
 */
const ProductDetailsTitle = styled(Text)<{compensateCloseIcon?: boolean}>`
  /* icon size + margin */
  margin-right: ${({compensateCloseIcon}) => (compensateCloseIcon ? 'calc(32px + 0.5rem)' : '0')};
`

const ProductDetailsDescription = styled(Text)`
  text-align: left;
  margin: 0;
  padding: 0;
`

const ProductDescription = styled(Text)`
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const OutOfStockNotice = styled(Notification)`
  margin-bottom: ${({theme}) => theme.spacing.xxxxsmall};
`

const SelectionIstructions = styled(Text)`
  display: block;
  margin-top: ${({theme}) => `-${theme.spacing.xxxsmall} `};
  margin-bottom: ${({theme}) => theme.spacing.xsmall};
  padding: 0;
`
const AdditionalInfoInput = styled(TextArea)`
  display: flex;
  width: -webkit-fill-available;
`
const AdditionalInfoLabel = styled(Label)`
  padding-bottom: ${({theme}) => theme.spacing.xxsmall};
`

const OptionsWrapper = styled.div`
  margin: ${({theme}) => `0 -${theme.spacingCustom.medium}`};
  padding: ${({theme}) => theme.spacing.small} ${({theme}) => theme.spacingCustom.medium} 0;
  font-size: 0.825rem;
  border-top: 1px solid ${({theme}) => theme.color.border.mediumSecondary};
  position: relative;
`

const fadeInFromNone = keyframes`
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const RecommendedOptionsWrapper = styled(OptionsWrapper)`
  border-top: none;
  margin: 0;
  padding: 0;
  animation: ${fadeInFromNone} 0.2s linear;
`

const SizeOptionsTitle = styled(Text)`
  display: block;
  text-align: left;
  margin: 0 0 ${({theme}) => theme.spacing.xsmall};
`
const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({theme}) => theme.spacing.xsmall};
`

const BottomWrapperFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${color.white};
  opacity: 1;
  box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
  z-index: 9000;
`

const BottomNotification = styled(Text)<{centered?: boolean}>`
  display: block;
  background-color: ${color.background.strongPrimary};
  color: ${color.white};
  padding: ${xsmall} ${({theme}) => theme.spacing.small};
  text-align: ${({centered}) => (centered ? 'center' : 'left')};
`

const BottomActionWrapperFixed = styled.div`
  align-items: center;
  justify-content: space-between;
  position: relative;
  display: flex;
  gap: ${({theme}) => theme.spacing.small};
  width: calc(100% - ${({theme}) => theme.spacingCustom.medium} * 2);
  padding: ${({theme}) => theme.spacing.small} ${({theme}) => theme.spacingCustom.medium}
    ${({theme}) => theme.spacingCustom.medium};
`

const ControlsAndPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`

const PriceLabel = styled(Text)`
  text-align: left;
`

const PriceValue = styled.div<{marginTop?: string}>`
  display: flex;
  align-items: baseline;
  margin-top: ${({theme, marginTop}) => marginTop || theme.spacing.xxsmall};
`

const Price = styled(Text)`
  display: flex;
  margin: 0;
  padding-left: ${(props) => props.theme.spacing.xsmall};
`

const PortionOptions = styled.span`
  font-weight: 400;
`

const LowestPricePrefix = styled(Text)`
  padding-right: ${({theme}) => theme.spacing.xxxxsmall};
`

const ProductInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: ${({theme}) => theme.spacing.xxsmall};
`

const ProductInfoDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing.xxsmall};
`

const ProductImageWrapper = styled.div`
  padding-left: ${({theme}) => theme.spacing.xxsmall};

  picture {
    display: block;
    height: 4.5rem;
    width: 4.5rem;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
`

const SectionTitle = styled(Text)`
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: ${({theme}) => theme.spacing.xxsmall} 0;
`

const RecommendedPortionWrapper = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.xsmall};
`

const RecommendedPortionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xsmall};
`

const RecommendedPortionDescription = styled(Text)<{lineClamp?: boolean}>`
  margin: 0;
  ${({lineClamp}) =>
    lineClamp &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `}
`

const RecommendedProductPrice = styled.div`
  display: flex;
  align-items: center;
`

const RecommendedOptionSectionName = styled(Text)`
  display: block;
  margin: ${(props) => props.theme.spacing.xxsmall} 0;
`

const SizeLabel = styled(Text)`
  margin: 0 ${({theme}) => theme.spacing.small} 0 0;
`

const ToastNotification = styled(Notification)`
  padding: ${(props) => props.theme.spacing.medium} 0 ${(props) => props.theme.spacing.medium}
    ${(props) => props.theme.spacing.large};
  margin: 0;
  border-right: none;
`

export {
  ActionButton,
  AdditionalInfoInput,
  AdditionalInfoLabel,
  BottomActionWrapperFixed,
  BottomNotification,
  BottomWrapperFixed,
  ButtonWrapper,
  ControlsAndPriceWrapper,
  DietTagsAbbreviations,
  EditButton,
  IconAdd,
  LowestPricePrefix,
  OptionsWrapper,
  OutOfStockNotice,
  PortionOptions,
  Price,
  PriceLabel,
  PriceValue,
  PriceWrapper,
  ProductBasicInfo,
  ProductCardTitleRow,
  ProductContainerButton,
  ProductDescription,
  ProductDetails,
  ProductDetailsContainer,
  ProductDetailsDescription,
  ProductDetailsImageWrapper,
  ProductDetailsTitle,
  ProductDetailsWrapper,
  ProductImageWrapper,
  ProductInfoDetails,
  ProductInfoWrapper,
  ProductSummaryContainer,
  ProductTitle,
  ProductTitleRow,
  RecommendedOptionSectionName,
  RecommendedOptionsWrapper,
  RecommendedPortionDescription,
  RecommendedPortionInfo,
  RecommendedPortionWrapper,
  RecommendedProductPrice,
  SectionTitle,
  SelectionIstructions,
  SizeLabel,
  SizeOptionsTitle,
  ToastNotification,
}
