import {ModalComponent, ModalContent} from './ModalStyles'

const Modal = (props: {
  size?: 'medium' | 'small' | 'large'
  width?: 'medium' | 'small' | 'large'
  testId?: string
  isOpen: boolean
  onClose?: () => void
  noPadding?: boolean
  maxWidth?: string
  children: React.ReactNode
}) => {
  const {size = 'medium', width = 'medium', testId, isOpen, onClose, noPadding, maxWidth, children} = props

  return (
    <ModalComponent
      rounding='rounded'
      sizing={size}
      width={width}
      data-testid={testId}
      open={isOpen}
      closeOnOutsideClick
      onClose={onClose}
      $noPadding={noPadding}
      $maxWidth={maxWidth}
    >
      <ModalContent>{children}</ModalContent>
    </ModalComponent>
  )
}

export default Modal
