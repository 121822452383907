import {Text} from '@s-group/design-system-components'
import {createPhoneHref} from 'utils/misc'
import {PhoneNumberLink} from './PhoneNumberStyles'

const PhoneNumber = ({description, phoneNumber}: {description: string; phoneNumber: string}) => (
  <Text data-testid='restaurant-phone-number' variant='body' sizing='small'>
    {description}
    <PhoneNumberLink href={createPhoneHref(phoneNumber)}>{phoneNumber}</PhoneNumberLink>
  </Text>
)

export default PhoneNumber
