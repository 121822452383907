import {Fragment} from 'react'

interface PriceProps {
  price: number
  signDisplay?: boolean
}
/**
 * Will format price in correct Finnish price format display (x,xx €) .
 * @param price number of the price including decimals
 * @param signDisplay if true show + sign infront of price if not 0
 * @returns JSX.element with formatting
 */
const formatPrice = ({price, signDisplay = false}: PriceProps) => {
  const priceWithCurrency = new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    signDisplay: signDisplay ? 'exceptZero' : 'never',
  }).format(price / 100)
  const nonBreakingSpace = String.fromCharCode(160)
  const splitPrice = priceWithCurrency.split(nonBreakingSpace)
  return (
    <span data-testid='price-container'>
      {splitPrice.map((priceEl: string, index: number) => {
        if (index + 1 < splitPrice.length) {
          return <Fragment key={index}>{priceEl + nonBreakingSpace}</Fragment>
        }
        return <span key={index}>{priceEl}</span>
      })}
    </span>
  )
}

/**
 * Will format price in correct Finnish price as plain text.
 * @param price number of the price including decimals
 * @returns string of the price
 */
export const formatPricePlainText = (price: number, signDisplay = false) =>
  new Intl.NumberFormat('fi-FI', {
    style: 'currency',
    currency: 'EUR',
    signDisplay: signDisplay ? 'exceptZero' : 'never',
  }).format(price / 100)

export default formatPrice
