import styled, {keyframes} from 'styled-components'

const rollAnimation = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`

const Path = styled.path`
  animation-name: ${rollAnimation};
  animation-duration: 750ms;
  animation-iteration-count: infinite;
  stroke: url('#Pattern');
  transform-origin: 40px 40px;
`

type LoaderProps = {
  /**
   * Make the loader dark, so that it works on light backgrounds.
   *
   * By default, the loader is light and works on dark backgrounds.
   */
  dark?: boolean
}

/* tslint:disable */
export const Loader = ({dark}: LoaderProps) => {
  const gradientStart = dark ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'
  const gradientEnd = 'rgba(255, 255, 255, 0.5)'
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' x='0' y='0' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient id='Gradient1' gradientTransform='rotate(90)'>
          <stop offset='0%' stopColor={gradientStart} />
          <stop offset='100%' stopColor={gradientEnd} />
        </linearGradient>
        <linearGradient id='Gradient2' gradientTransform='rotate(90)'>
          <stop offset='0%' stopColor='rgba(255, 255, 255, 0)' stopOpacity='0' />
          <stop offset='100%' stopColor={gradientEnd} />
        </linearGradient>
        <pattern id='Pattern' x='0' y='0' width='80' height='80' patternUnits='userSpaceOnUse'>
          <g transform='rotate(0)'>
            <rect shapeRendering='crispEdges' x='0' y='0' width='40' height='80' fill='url(#Gradient1)' />
            <rect shapeRendering='crispEdges' x='40' y='0' width='40' height='80' fill='url(#Gradient2)' />
          </g>
        </pattern>
      </defs>
      <Path
        fill='transparent'
        transform='rotate(360, 0, 0)'
        strokeWidth='8'
        d='M4,40a36,36 0 1,0 72,0a36,36 0 1,0 -72,0'
      />
    </svg>
  )
}
