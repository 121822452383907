import {useLocation} from 'react-router'

export const defineRaflaamoApiUrl = (origin: string): string => {
  switch (origin) {
    case 'http://localhost:3000':
      return 'http://localhost:8000'
    case 'https://tilaa.raflaamo.fi':
      return 'https://www.raflaamo.fi'
    case 'https://tilaa-qa.beta-test.raflaamo.fi':
      return 'https://www.qa.beta-test.raflaamo.fi'
    default:
      return 'https://www.dev.beta-test.raflaamo.fi'
  }
}

/**
 * This hook will define urls from origin and react router based on the current path
 * @returns redirectUrl defining needed redirectUrl endpoints for dev, mr qa and prod,
 *          postLoginUrl which can take to more accurate path of whitelisted urls
 *          logoutUrl through which logout has to be done to delete the httpOnly cookie
 */
const useSIDLogin = () => {
  const {origin} = window.location
  const location = useLocation()
  const currentPath = location.pathname
  const postLoginUrl = `${origin}${currentPath}`
  const redirectUrl = defineRaflaamoApiUrl(origin)
  const logoutUrl = `${defineRaflaamoApiUrl(origin)}/api/auth/logout`
  return {postLoginUrl, redirectUrl, logoutUrl}
}

export default useSIDLogin
