import {large, medium, small, xsmall} from '@marketplace/shared-lib/src/utils/themeVariables'
import {Button, Heading, Text} from '@s-group/design-system-components'
import {SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY} from '@s-group/design-system-tokens/web/tokens/sbrand/colors.es6'
import FocusLock from 'react-focus-lock'
import styled, {css} from 'styled-components'

export const LoginPopUpWrapper = styled.section`
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 0;
  position: absolute;
  top: 60px;
  width: 300px;
  z-index: 1000;
  right: ${large};
  border-radius: ${({theme}) => theme.spacing.xxxsmall};
  box-shadow: 0 ${medium} ${medium} 0 rgba(1 1 1 / 25%);
`

export const LogoWrapper = styled.div`
  text-align: center;
  color: ${SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY};
`

export const PopupRow = styled.div<{
  $noBottomBorder?: boolean
  $linkOnly?: boolean
  $noPadding?: boolean
  $noGap?: boolean
  $alignCenter?: boolean
}>`
  border-bottom: ${({theme, $noBottomBorder}) =>
    $noBottomBorder ? 'none' : `1px solid ${theme.color.background.weakGrey}`};
  padding: ${({$noPadding, $linkOnly}) => {
    if ($noPadding) return 0
    if ($linkOnly)
      return css`
        ${xsmall} ${small};
      `
    return small
  }};
  width: 100%;
  gap: ${({$noGap}) => ($noGap ? 0 : small)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({$alignCenter}) => ($alignCenter ? 'center' : undefined)};
`

export const PopUpHeading = styled(Heading)`
  margin: ${({theme}) => theme.spacing.xxsmall} 0;
`

export const PopUpText = styled(Text)`
  margin: ${({theme}) => theme.spacing.xxsmall} 0;
`

export const CurrentBonusAmount = styled(Text)`
  margin: 0;
  color: ${SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY};
`
export const CustomerName = styled(Heading)`
  margin: 0;
  text-align: left;
  width: calc(100% - 1.6rem);
`

export const FlexText = styled.span`
  flex-grow: 1;
  text-align: left;
`

export const FocusLockElement = styled(FocusLock)`
  flex-grow: 1;
`

export const CloseButton = styled(Button)`
  position: absolute;
  right: 0;
  top: ${({theme}) => theme.spacing.xxxxsmall};
  z-index: 1;
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing.xxsmall};
`

export const IconNavigationLinkExternalWrapper = styled.div`
  margin-left: auto;
`

export const ButtonForLoggedInUsers = styled.button`
  background-color: transparent;
  border: 0 solid transparent;
  color: ${({theme}) => theme.color.background.strongerGrey};
  font-size: 1rem;
  display: flex;
  gap: ${({theme}) => theme.spacing.small};
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
`

export const DoubleIconWrapper = styled.div`
  position: relative;

  span:nth-child(2) {
    position: absolute;
    left: 0.9rem;
    top: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
`

export const NoIconWrapper = styled.div`
  padding-left: 2rem;
  text-align: left;
`

export const Triangle = styled.div`
  background-color: white;
  height: 38px;
  position: absolute;
  right: 54px;
  top: -9px;
  transform: rotateY(0deg) rotate(45deg);
  width: 38px;
  z-index: -1;
`

export const LoginLinksWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${xsmall};
`

export const NotificationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  gap: ${small};

  a {
    width: fit-content;
  }
`
