import {useEffect, useRef} from 'react'

export const useAfterModalOpen = (isOpen: boolean, afterOpen: () => void) => {
  const hasAfterOpenBeenCalled = useRef(false)

  useEffect(() => {
    // call afterOpen only once when modal is opened
    if (isOpen && !hasAfterOpenBeenCalled.current) {
      hasAfterOpenBeenCalled.current = true
      afterOpen()
    }
    // when modal is closed, reset the flag
    if (!isOpen) {
      hasAfterOpenBeenCalled.current = false
    }
  }, [afterOpen, isOpen])
}
