import {Text} from '@s-group/design-system-components'
import styled from 'styled-components'

const RecommendationsWrapper = styled.div`
  padding: ${({theme}) => theme.spacing.xsmall};
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing.xxsmall};
`

const RecommendationsHeader = styled(Text)`
  text-align: center;
  padding-bottom: ${({theme}) => theme.spacing.xxxsmall};
`

export {RecommendationsHeader, RecommendationsWrapper}
