/* eslint-disable @typescript-eslint/no-explicit-any */
import {Button, Modal} from '@s-group/design-system-components'
import styled from 'styled-components'

export const ModalComponent = styled(Modal)<{$maxWidth?: string; $noPadding?: boolean}>`
  ${({$maxWidth}) => $maxWidth && `max-width: ${$maxWidth};`}
  .modal-body {
    ${({$noPadding}) => $noPadding && `padding: 0;`}
  }
`
export const ModalContent = styled.div<{$contentWidth?: string}>`
  display: flex;
  justify-content: center;
  ${({$contentWidth}) => $contentWidth && `width: ${$contentWidth};`}
`

export const ModalContentWrapper = styled.span`
  width: 100%;
`

export const StyledModalBottom = styled(Modal)<{
  $small: boolean
  $maxWidth?: string
  $background?: string
  $animate?: boolean
  $noPadding?: boolean
}>`
  background: ${({$background, theme}) => $background ?? theme.color.white};
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: ${({$maxWidth}) => $maxWidth ?? '100vw'};
  ${({$small}) => $small && 'max-width: 450px'};
  overflow: hidden;

  /* Note: we must use svh (small viewport height) instead of vh.
   * svh acknowledges any elements taking up vertical space (e.g. browser
   * address bar)
   * fallback for old browsers (e.g. FF90) */
  max-height: calc(90vh - 125px - 3rem);

  /* modern browsers: height of the modal - height of the bottom action box */
  max-height: calc(90svh - 125px);
  transform: ${({$animate}) => ($animate ? 'translate(0,0)' : 'translate(0, 90vh)')};
  transition: transform 0.14s ease-in-out;

  .modal-body {
    ${({$noPadding}) => $noPadding && `padding: 0;`}
    z-index: 7000;
  }
`

export const ModalBottomCloseButton = styled(Button)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  z-index: 9000;
`

export const StyledModalFullScreen = styled(Modal)<{$opacity?: number}>`
  background: ${({theme}) => theme.color.black};
  color: ${({theme}) => theme.color.white};
  width: 100vw;
  height: 100vh; /* fallback for old browsers */
  height: 100svh; /* modern browsers */
  position: relative;
  z-index: 200;
  overflow: auto;
  opacity: ${({$opacity}) => $opacity ?? 1};
  transition: all 0.3s ease-in-out;
  font-family: ${({theme}) => theme.font.family.roboto};
`
export const FullScreenCloseButton = styled.div`
  position: absolute;
  margin: 0;
  top: ${({theme}) => theme.spacing.xxlarge};
  right: ${({theme}) => theme.spacing.xxlarge};
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  cursor: pointer;
`

export const ModalWrapper = styled.div<{whiteBackground?: boolean}>`
  color: ${({whiteBackground, theme}) => (whiteBackground ? theme.color.black : theme.color.white)};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
`

export const ModalTextWrapper = styled.div`
  margin-top: 2rem;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ModalButtonWrapper = styled.div`
  padding: 2rem;
  background-color: ${({theme}) => theme.color.lightGrey};
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ModalBottomContentWrapper = styled.div`
  height: 100%;
`
